<template>
    <div :class="classObj" class="app-wrapper" :style="{ '--current-color': theme }">
      <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
      <sidebar v-if="!sidebar.hide" class="sidebar-container" />
      <div v-if="!deviceKey" :class="{ hasTagsView: needTagsView, sidebarHide: sidebar.hide }" class="main-container">
        <div :class="{ 'fixed-header': fixedHeader }">
          <navbar />
          <tags-view v-if="needTagsView" />
        </div>
        <app-main />
        <right-panel>
          <settings />
        </right-panel>
      </div>
      <div v-if="deviceKey" :class="{ hasTagsView: needTagsView, sidebarHide: sidebar.hide }" class="main-container">
        <div :class="{ 'fixed-header': fixedHeader }" class="header_title">
          <div class="svg_icons">
            <el-popover placement="top" width="160" v-model="visible">
              <p>确定注销并退出系统吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="logout()">确定</el-button>
              </div>
              <svg-icon slot="reference" class="hamburger" icon-class="headerSvg" />
            </el-popover>
  
          </div>
          <div class="title"> {{ headerTitle ? headerTitle : '' }}</div>
        </div>
        <app-main />
      </div>
    </div>
  </template>
  
  <script>
  import RightPanel from '@/components/RightPanel'
  import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components'
  import ResizeMixin from './mixin/ResizeHandler'
  import { mapState } from 'vuex'
  import { getDevice, getHeaderTitle } from '@/utils/auth'
  import { getPath } from "@/utils/ruoyi";
  
  export default {
    name: 'Layout',
    components: {
      AppMain,
      Navbar,
      RightPanel,
      Settings,
      Sidebar,
      TagsView
    },
    data() {
      return {
        visible: false
      }
    },
    mixins: [ResizeMixin],
    computed: {
      ...mapState({
        theme: state => state.settings.theme,
        sideTheme: state => state.settings.sideTheme,
        sidebar: state => state.app.sidebar,
        device: state => state.app.device,
        needTagsView: state => state.settings.tagsView,
        fixedHeader: state => state.settings.fixedHeader
      }),
      classObj() {
        return {
          hideSidebar: !this.sidebar.opened,
          openSidebar: this.sidebar.opened,
          withoutAnimation: this.sidebar.withoutAnimation,
          mobile: this.device === 'mobile'
        }
      },
      variables() {
        return this.$variables;
      },
      deviceKey() {
        return getDevice()
      },
      headerTitle() {
        return getHeaderTitle()
      },
    },
    mounted() {
      console.log(this.$theme)
    },
    methods: {
      handleClickOutside() {
        this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
      },
      logout() {
        this.$store.dispatch('LogOut').then(() => {
          location.href = getPath('/index');
          this.visible = false
        })
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "~@/assets/styles/mixin.scss";
  
  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
  
    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }
  
  .header_title {
    height: 56px;
    line-height: 56px;
    display: flex;
    background: #3875C5;
  
    .svg_icons {
      width: 3rem;
      text-align: center;
      padding-top: 0.4rem;
    }
  
    .title {
      width: calc(100% - 3rem);
      text-align: center;
      font-size: 1.5rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      padding-right: 3rem;
    }
  }
  
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }
  
  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$base-sidebar-width});
    transition: width 0.28s;
  
  }
  
  .hideSidebar .fixed-header {
    width: calc(100% - 54px);
  }
  
  .sidebarHide .fixed-header {
    width: calc(100%);
  }
  
  .mobile .fixed-header {
    width: 100%;
  }
  </style>
  