var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-wrapper",
      class: _vm.classObj,
      style: { "--current-color": _vm.theme },
    },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      !_vm.sidebar.hide
        ? _c("sidebar", { staticClass: "sidebar-container" })
        : _vm._e(),
      !_vm.deviceKey
        ? _c(
            "div",
            {
              staticClass: "main-container",
              class: {
                hasTagsView: _vm.needTagsView,
                sidebarHide: _vm.sidebar.hide,
              },
            },
            [
              _c(
                "div",
                { class: { "fixed-header": _vm.fixedHeader } },
                [_c("navbar"), _vm.needTagsView ? _c("tags-view") : _vm._e()],
                1
              ),
              _c("app-main"),
              _c("right-panel", [_c("settings")], 1),
            ],
            1
          )
        : _vm._e(),
      _vm.deviceKey
        ? _c(
            "div",
            {
              staticClass: "main-container",
              class: {
                hasTagsView: _vm.needTagsView,
                sidebarHide: _vm.sidebar.hide,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "header_title",
                  class: { "fixed-header": _vm.fixedHeader },
                },
                [
                  _c(
                    "div",
                    { staticClass: "svg_icons" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: { placement: "top", width: "160" },
                          model: {
                            value: _vm.visible,
                            callback: function ($$v) {
                              _vm.visible = $$v
                            },
                            expression: "visible",
                          },
                        },
                        [
                          _c("p", [_vm._v("确定注销并退出系统吗？")]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                margin: "0",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      _vm.visible = false
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.logout()
                                    },
                                  },
                                },
                                [_vm._v("确定")]
                              ),
                            ],
                            1
                          ),
                          _c("svg-icon", {
                            staticClass: "hamburger",
                            attrs: {
                              slot: "reference",
                              "icon-class": "headerSvg",
                            },
                            slot: "reference",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      " " + _vm._s(_vm.headerTitle ? _vm.headerTitle : "")
                    ),
                  ]),
                ]
              ),
              _c("app-main"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }