import { decrypt, encrypt } from "@/utils/jsencrypt";

const AccessTokenKey = 'ACCESS_TOKEN'
const RefreshTokenKey = 'REFRESH_TOKEN'

const AccessTokenKeySurvey = 'ACCESS_TOKEN_SURVEY'
const RefreshTokenKeySurvey = 'REFRESH_TOKEN_SURVEY'

const CemTokenKey = 'CEMTOKEN'
const CemAccountKey = 'CEMACCOUNT'
const UsernameKey = 'USERNAME'
const PasswordKey = 'PASSWORD'
const RememberMeKey = 'REMEMBER_ME'

// ========== Token 相关 ==========

export function getAccessToken() {
  return localStorage.getItem(AccessTokenKey)
}

export function getRefreshToken() {
  return localStorage.getItem(RefreshTokenKey)
}

export function setToken(token) {
  localStorage.setItem(AccessTokenKey, token.accessToken)
  localStorage.setItem(RefreshTokenKey, token.refreshToken)
}

export function removeToken() {
  localStorage.removeItem(AccessTokenKey)
  localStorage.removeItem(RefreshTokenKey)


  localStorage.removeItem(AccessTokenKeySurvey)
  localStorage.removeItem(RefreshTokenKeySurvey)


  localStorage.removeItem(CemAccountKey)
  localStorage.removeItem(CemTokenKey)
  localStorage.removeItem(UsernameKey)
  localStorage.removeItem(PasswordKey)
  localStorage.removeItem(RememberMeKey)
}

// ========== 账号相关 ==========


export function getCemAccount() {
  return localStorage.getItem(CemAccountKey)
}
export function setCemAccount(cemaccount) {
  localStorage.setItem(CemAccountKey, cemaccount)
}
export function removeCemAccount() {
  localStorage.removeItem(CemAccountKey)
}
export function getCemToken() {
  return localStorage.getItem(CemTokenKey)
}
export function setCemToken(cemtoken) {
  localStorage.setItem(CemTokenKey, cemtoken)
}
export function removeCemToken() {
  localStorage.removeItem(CemTokenKey)
}

export function getUsername() {
  return localStorage.getItem(UsernameKey)
}
export function setUsername(username) {
  localStorage.setItem(UsernameKey, username)
}

export function removeUsername() {
  localStorage.removeItem(UsernameKey)
}

export function getPassword() {
  const password = localStorage.getItem(PasswordKey)
  return password ? decrypt(password) : undefined
}

export function setPassword(password) {
  localStorage.setItem(PasswordKey, encrypt(password))
}

export function removePassword() {
  localStorage.removeItem(PasswordKey)
}

export function getRememberMe() {
  return localStorage.getItem(RememberMeKey) === 'true'
}

export function setRememberMe(rememberMe) {
  localStorage.setItem(RememberMeKey, rememberMe)
}

export function removeRememberMe() {
  localStorage.removeItem(RememberMeKey)
}

// ========== 租户相关 ==========

const TenantIdKey = 'TENANT_ID'
const TenantNameKey = 'TENANT_NAME'

export function getTenantName() {
  return localStorage.getItem(TenantNameKey)
}

export function setTenantName(username) {
  localStorage.setItem(TenantNameKey, username)
}

export function removeTenantName() {
  localStorage.removeItem(TenantNameKey)
}

export function getTenantId() {
  return localStorage.getItem(TenantIdKey)
}

export function setTenantId(username) {
  localStorage.setItem(TenantIdKey, username)
}

export function removeTenantId() {
  localStorage.removeItem(TenantIdKey)
}


// ========== 设备相关 ==========

const DeviceKey = 'DEVICEKEY'

export function getDevice() {
  return localStorage.getItem(DeviceKey)
}

export function setDevice(key) {
  localStorage.setItem(DeviceKey, key)
}

export function removeDevice() {
  localStorage.removeItem(DeviceKey)
}
const HeaderTitle = 'HEADERTITLE'
export function getHeaderTitle() {
  return localStorage.getItem(HeaderTitle)
}

export function setHeaderTitle(key) {
  localStorage.setItem(HeaderTitle, key)
}

export function removeHeaderTitle() {
  localStorage.removeItem(HeaderTitle)
}
// ============预警背景水印生成=======
const WatermarkKey = 'WATERMARKKYY'
export function getWatermarkKey() {
  return localStorage.getItem(WatermarkKey)
}

export function setWatermarkKey(key) {
  localStorage.setItem(WatermarkKey, key)
}

export function removeWatermarkKey() {
  localStorage.removeItem(WatermarkKey)
}
const WatermarkGaryKey = 'WATERMARKGARYKYY'
export function getWatermarkGaryKey() {
  return localStorage.getItem(WatermarkGaryKey)
}

export function setWatermarkGaryKey(key) {
  localStorage.setItem(WatermarkGaryKey, key)
}

export function removeWatermarkGaryKey() {
  localStorage.removeItem(WatermarkGaryKey)
}