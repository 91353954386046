import { getMetricsTreeData, getMetricsList, getDataSourceTree, updateMetricsStatus, getMetricsMention, getAnalysisMetricsMention } from '@/api/metricsLib/index'

import {  getMetricsLibraryList } from '@/api/journeyMap/metricsLibrary.js'
import { Message } from 'element-ui'
const getMap = (obj, item, dataMap, children = 'childMetricsNodes', key = 'id') => {
	obj[item[key]] = item.metricsName;
	dataMap[item[key]] = item;
	if (item[children]) {
		item[children].forEach(v => getMap(obj, v, dataMap, children, key))
	}
}
const state = {
	metricsTreeData: [],
	metricsTreeLoading: false,
	metricsTreeDataNameMap: {},
	metricsTreeDataMap: {},
	isList: false,
	metricsListTotal: 0,
	dataSourceTree: [],
	dataSourceTreeMap: [],
	dataSourceTreeNameMap: {},
	dataSourceTreeCodeMap: {},
	metricsMentionData: [],
	metricsMentionMap: {},
	metricsMentionDatas: [],
	metricsMentionMaps: {},

}

const mutations = {
	UPDATE_STATE: (state, { key = '', data = null }) => {
		state[key] = data;
	},
}

const actions = {
	async getMetricsData({ state, commit, dispatch }, { params }) {
		commit('UPDATE_STATE', { key: 'metricsTreeLoading', data: true })
		const isList = params.metricsName ? true : false
		const fn = isList ? getMetricsList : getMetricsTreeData
		const res = await fn(params)
		commit('UPDATE_STATE', { key: 'metricsTreeLoading', data: false })
		if (isList) commit('UPDATE_STATE', { key: 'isList', data: true })

		if (res.code === 200) {

			const data = JSON.parse(JSON.stringify(isList ? res.data.list : res.data))
			commit('UPDATE_STATE', { key: 'metricsTreeData', data })

			// 获取名称/数据map
			const obj = {};
			const dataMap = {};
			for (let i = 0; i < data.length; i++) {
				getMap(obj, data[i], dataMap)
			}
			commit('UPDATE_STATE', { key: 'metricsTreeDataNameMap', data: obj })
			commit('UPDATE_STATE', { key: 'metricsTreeDataMap', data: dataMap })

			if (isList) commit('UPDATE_STATE', { key: 'metricsListTotal', data: res.data.total })
		}
	},

	async getMetricsLibraryList({  }, { params }) {
		try {
			const res = await getMetricsLibraryList(params)
			console.log(res)
		} catch (error) {
			
		}
	},

	getFullName({ state, dispatch }, { v, nameList }) {
		const { metricsTreeDataMap } = state
		nameList.unshift(v.metricsName)

		if (v.parentId !== '0') {
			dispatch('getFullName', { v: metricsTreeDataMap[v.parentId], nameList })
		}
	},
	// 获取数据源
	async getDataSourceTree({ state, commit }, { callback }) {
		const res = await getDataSourceTree();

		if (res.code === 200) {
			// 获取名称/数据map
			const obj = {};
			const dataMap = {};
			let data = res.data
			const dataIdMap = {}
			if (data && data.length && data[0].datasourceName === '内部数据') data = data.reverse()
			commit('UPDATE_STATE', { key: 'dataSourceTree', data })
			for (let i = 0; i < data.length; i++) {
				getMap(obj, data[i], dataMap, 'childNodes', 'datasourceCode')
				getMap(obj, data[i], dataIdMap, 'childNodes',)
			}
			commit('UPDATE_STATE', { key: 'dataSourceTreeMap', data: dataIdMap })
			commit('UPDATE_STATE', { key: 'dataSourceTreeCodeMap', data: dataMap })
			commit('UPDATE_STATE', { key: 'dataSourceTreeNameMap', data: obj })

			if (callback) callback()
		}
	},

	async updateMetricsStatus({ state, dispatch }, { data, callback }) {
		const res = await updateMetricsStatus(data);
		if (res.code === 200) {
			Message.success('修改成功')
			callback()
		}

	},

	async getMetricsMention({ commit }, { data, callback }) {
		const res = await getMetricsMention(data)
		if (res.code === 200 && res.data) {
			const obj = {}
			const data = res.data;
			for (let i = 0; i < data.length; i++) {
				obj[data[i].metricsId] = data[i]
			}
			commit('UPDATE_STATE', { key: 'metricsMentionData', data })
			commit('UPDATE_STATE', { key: 'metricsMentionMap', data: obj })
			if (callback) callback()
		}
	},

	async getAnalysisMetricsMention({ commit }, { data, callback }) {
		const res = await getAnalysisMetricsMention(data)
		if (res.code === 200 && res.data) {
			const obj = {}
			const data = res.data;
			for (let i = 0; i < data.length; i++) {
				obj[data[i].metricsId] = data[i]
			}
			commit('UPDATE_STATE', { key: 'metricsMentionDatas', data })
			commit('UPDATE_STATE', { key: 'metricsMentionMaps', data: obj })
			if (callback) callback()
		}
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
