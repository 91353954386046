<template>
  <div>
    <!-- <svg-icon icon-class="question" @click="goto"/> -->
  </div>
</template>

<script>
export default {
  name: 'GuyuaiDoc',
  data() {
    return {
      // url: 'http://www.iocoder.cn/Guyuai/build-debugger-environment/?guyuai'
    }
  },
  methods: {
    // goto() {
    //   window.open(this.url)
    // }
  }
}
</script>
