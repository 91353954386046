import request from '@/utils/request'

const objectToString = (paramsObj) => {
  if (!paramsObj) return ''
  let keys = Object.keys(paramsObj)
  if (!keys[0]) return ''
  const parmStrList = keys.reduce((pre, key) => {
    pre.push(`${key}=${paramsObj[key] || ''}`)
    return pre
  }, [])
  console.log(parmStrList)
  return '?' + parmStrList.join("&")
}

const isArray = (list) => {
  return list instanceof Array
}

// 获取指标库列表
export function getMetricsLibList(params) {
  return request({
    url: '/cem/metrics-lib/page',
    method: 'get',
    params: {
      ...{
        pageNo: 1,
        pageSize: 100,
      }, ...params
    }
  })
}

// 修改指标库
export function updateMetricsLib(data) {
  return request({
    url: '/cem/metrics-lib/update',
    method: 'put',
    data
  })
}

// 创建指标库
export function createMetricsLib(data) {
  return request({
    url: '/cem/metrics-lib/create',
    method: 'post',
    data
  })
}

// 根据指标库id 获取 指标列表
export function getMetricsList(params) {
  return request({
    url: `/cem/metrics/page`,
    method: 'get',
    params
  })
}

// 根据id 获取指标树
export function getMetricsTreeData(params) {
  return request({
    url: `/cem/metrics/tree`,
    method: 'get',
    params
  })
}

// 创建体验点
export function createMetrics(data) {
  return request({
    url: '/cem/metrics/create',
    method: 'post',
    data
  })
}

// 修改体验点
export function updateMetrics(data) {
  return request({
    url: '/cem/metrics/update',
    method: 'put',
    data
  })
}

// 获取体验点列表
export function getCodeTable(params) {
  return request({
    url: `/cem/code-table/page`,
    method: 'get',
    params
  })
}


// 获取体验点列表
export function getCodeTableTree(params) {
  return request({
    url: `/cem/code-table/tree`,
    method: 'get',
    params
  })
}
// 获取指标/关联数据
export function getDataSourceList(data) {
  return request({
    url: `/cem/datasource/list`,
    method: 'get',
    params: {
      ids: data
    }
  })
}

// 获取数据源类型
export function getDataSourceCategoryList() {
  return request({
    url: `/cem//datasource-category/page`,
    method: 'get',
  })
}

// 根据ID 获取指标库信息
export function getMetricsLibInfo(id) {
  return request({
    url: `/cem/metrics-lib/get?id=${id}`,
    method: 'get',
  })
}

// 修改指标库
export function getMetricsLibListDep(params) {
  return request({
    url: '/cem/metrics-lib/list',
    method: 'get',
    params
  })
}

// 获取数据源分类
export function getDataSourceCategory(params) {
  return request({
    url: '/cem/datasource/tree',
    method: 'get',
    params
  })
}
// 根据细分子类的id获取数据源
export function getDataSourceById(params) {
  return request({
    url: '/cem/datasource/page',
    method: 'get',
    params
  })
}


// 根据指标Id创建关联数据源
export function createDataSourceByMetricsId(data) {
  return request({
    url: '/cem/metrics-cal-config/create',
    method: 'post',
    data
  })
}

// 根据指标Id创建关联数据源
export function createDataSourceBatch(data) {
  return request({
    url: '/cem/metrics-cal-config/add',
    method: 'post',
    data
  })
}

// 根据指标Id创建关联数据源
export function createDataSourceBatchSurvey(data) {
  return request({
    url: '/cem/metrics-cal-config/adds',
    method: 'post',
    data
  })
}

// 根据指标id 获取数据列表
export function getDataSourceByMetricsId(params) {
  return request({
    url: '/cem/metrics-cal-config/page',
    method: 'get',
    params
  })
}

// 根据指标id 修改数据源
export function updateDataSourceByMetricsId(data) {
  return request({
    url: '/cem/metrics-cal-config/update',
    method: 'put',
    data
  })
}

// 根据指标库ID 获取指标重算配置
export function getMetricsRecalById(params) {
  return request({
    url: '/cem/metrics-recal/get-by-metric-lib',
    method: 'get',
    params
  })
}

// 创建数据重算
export function createMetricsRecal(data) {
  return request({
    url: '/cem/metrics-recal/create',
    method: 'post',
    data
  })
}
export function deleteMetricsRecalById(params) {
  return request({
    url: '/cem/metrics-recal/delete-by-metric-lib',
    method: 'delete',
    params
  })
}

// 根据问卷id 获取问卷题目、选项
export function getQuestionnaireById(params) {
  return request({
    url: '/cem/datasource/get-questionnaire-structure',
    method: 'get',
    params
  })
}

// 根据问卷id 获取问卷题目、选项
export function getBatchDatasource(params) {
  return request({
    url: '/cem/metrics-cal-config/pageAndGroupByDatasource',
    method: 'get',
    params
  })
}

// 删除指标
export function deteleMetricsById(params) {
  return request({
    url: '/cem/metrics/delete',
    method: 'delete',
    params
  })
}

// 获取数据源树结构
export function getDataSourceTree() {
  return request({
    url: '/cem/datasource/tree',
    method: 'get',
  })
}

// 批量更新指标状态
export function updateMetricsStatus(params) {
  return request({
    url: `/cem/metrics/update-status?metricsLibId=${params.metricsLibId}&isEnable=${params.isEnable}&ids=${params.ids}`,
    method: 'put',
    // params
  })
}

// 导出指标树
export function downloadExcel(params) {
  return request({
    url: `/cem/metrics/export-excel`,
    method: 'get',
    params,
    responseType: 'blob'
  })
}

// 合并指标
export function metricsMerge(params) {
  return request({
    url: `/cem/metrics/merge?metricsLibId=${params.metricsLibId}&destinationMetricsId=${params.destinationMetricsId}&sourceMetricsIds=${params.sourceMetricsIds}`,
    method: 'put',
  })
}
// 获取租户下信息
export function getAllDic() {
  return request({
    url: `/cem/dic/all`,
    method: 'get',
  })
}

// 批量关联数据源 - 广汽
export function createBatchDatasourceGQ(data) {
  return request({
    url: `/cem/metrics-lib-cal-config/create`,
    method: 'post',
    data
  })
}

// 批量关联数据源- 广汽
export function getBatchDatasourceListGQ(params) {
  return request({
    url: `/cem/metrics-lib-cal-config/page`,
    method: 'get',
    params
  })
}

// 更新批量关联的数据源 - 广汽
export function updateBatchDatasourceGQ(data) {
  return request({
    url: `/cem/metrics-lib-cal-config/update`,
    method: 'put',
    data
  })
}


// 更新批量关联的数据源 - 广汽
export function deleteBatchDatasourceGQ(params) {
  return request({
    url: `/cem/metrics-lib-cal-config/delete`,
    method: 'delete',
    params
  })
}

// 获取指标树 包含体验值/提及量
export function getMetricsMention(data) {
  return request({
    url: `/dashboard/exp/metrics-mention`,
    method: 'post',
    data: data?.brand ? isArray(data.brand) ? data.brand?.length ? { ...data, ...{ brand: data.brand } } : { ...data, ...{ brand: undefined } } : { ...data, ...{ brand: data.brand.indexOf(',') !== -1 ? data.brand.split(',') : [data.brand] } } : data
  })
}
// 获取指标树 包含体验值/提及量 
export function getAnalysisMetricsMention(data) {
  return request({
    url: `/dashboard/competitive-analysis/metrics-mention`,
    method: 'post',
    data
  })
}
//  获取人群所以标签
export function getLabelOptions() {
  return request({
    url: '/cem/label-value/label-options',
    method: 'get',
  })
}