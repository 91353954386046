import html2canvas from 'html2canvasde'

export const getMonthDays = (step) => {
  var now = new Date()
  var nowMonth = now.getMonth() // 当前月
  if (step !== null) {
    nowMonth += step
  }
  var monthStartDate = new Date(now.getFullYear(), nowMonth, 1)
  var monthEndDate = new Date(now.getFullYear(), nowMonth + 1, 1)
  var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24)
  return days
}
export const getStartDayOfWeek = (step) => {
  var now = new Date() // 当前日期
  var nowDayOfWeek = now.getDay()
  var nowDay = now.getDate() // 当前日
  var nowMonth = now.getMonth() // 当前月
  var day = nowDayOfWeek || 7
  var resultDay = nowDay + 1 - day
  if (step !== null) {
    resultDay += (step * 7)
  }
  return new Date(now.getFullYear(), nowMonth, resultDay)
}

export const getEndDayOfWeek = (step) => {
  var now = new Date() // 当前日期
  var nowDayOfWeek = now.getDay()
  var nowDay = now.getDate() // 当前日
  var nowMonth = now.getMonth() // 当前月
  var day = nowDayOfWeek || 7
  var resultDay = nowDay + 7 - day
  if (step !== null) {
    resultDay += (step * 7)
  }
  return new Date(now.getFullYear(), nowMonth, resultDay)
}

export const getStartDayOfMonth = (step) => {
  var now = new Date()
  var nowMonth = now.getMonth() // 当前月
  if (step !== null) {
    nowMonth += step
  }
  var monthStartDate = new Date(now.getFullYear(), nowMonth, 1)
  return monthStartDate
}

export const getEndDayOfMonth = (step) => {
  var now = new Date()
  var nowMonth = now.getMonth() // 当前月
  var days = getMonthDays()

  if (step !== null) {
    nowMonth += step
    days = getMonthDays(step)
  }

  var monthEndDate = new Date(now.getFullYear(), nowMonth, days)
  return monthEndDate
}

export const getStartQuarter = (step) => {
  var now = new Date()
  var nowMonth = now.getMonth()
  var startMonth = Math.floor((nowMonth / 3)) * 3
  if (step !== null) {
    startMonth += (step * 3)
  }
  return new Date(now.getFullYear(), startMonth, 1)
}

export const getEndQuarter = (step) => {
  var now = new Date()
  var nowMonth = now.getMonth()
  const quar = Math.floor(nowMonth / 3)
  var endMonth = quar * 3 + 2

  if (step !== null) {
    endMonth += (step * 3)
  }

  const days = (endMonth === 5 || endMonth === 8) ? 30 : 31
  return new Date(now.getFullYear(), endMonth, days)
}

export const getStartYear = (step) => {
  var now = new Date()
  var year = now.getFullYear()
  if (step !== null) {
    year += step
  }
  return new Date(year, 0, 1)
}

export const getEndYear = (step) => {
  var now = new Date()
  var year = now.getFullYear()
  if (step !== null) {
    year += step
  }
  return new Date(year, 11, 31)
}

export const getCycle = (times) => {
  var start = new Date(times[0]).getTime()
  var end = new Date(times[1]).getTime()
  const day = end - start

  var preEnd = new Date(start).getTime() - 1
  var preStart = new Date(start - day).getTime()

  return [preStart, preEnd]
}

export const getYearOrYear = (times) => {
  const start = new Date(times[0])
  const end = new Date(times[1])
  const startYear = start.getFullYear();
  const startMonth = start.getMonth();
  const startDay = start.getDate();
  const endYear = end.getFullYear();
  const endMonth = end.getMonth();
  const endDay = end.getDate() + 1;

  var preStart = new Date(startYear - 1, startMonth, startDay).getTime()
  var preEnd = new Date(endYear - 1, endMonth, endDay).getTime() - 1
  return [preStart, preEnd]
}

export const getLastYear = () => {
  const today = new Date()
  const y = today.getFullYear() - 1;
  const m = today.getMonth();
  const d = today.getDate()
  const start = new Date(y, m, d).getTime()
  const end = new Date(today).getTime()
  console.log(y, m, d)
  return [start, end]
}

export function exportImg(imgName, idName) {
  const canvasID = document.getElementById(idName)
  const a = document.createElement('a')
  html2canvas(canvasID, { useCORS: true }).then(canvas => {
    const dom = document.body.appendChild(canvas)
    dom.style.display = 'none'
    a.style.display = 'none'
    document.body.removeChild(dom)
    const blob = dataURLToBlob(dom.toDataURL('image/png', 1))
    a.setAttribute('href', URL.createObjectURL(blob))
    a.setAttribute('download', imgName + '.png')
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(blob)
    document.body.removeChild(a)
  })
}


export function dataURLToBlob(dataurl) { // ie 图片转格式
  const arr = dataurl.split(','); const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1]); let n = bstr.length; const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

// 获取矩阵散点颜色
export function getMatrixColor(a, b, xAverage, yAverage) {
  const color = {
    green: '#0FC883',
    red: '#E20707',
    yello: '#FFB900',
    purple: '#B025FF',
    other: '#C9CDD4'
  }
  if (a > xAverage && b < yAverage) {
    return color.green
  } else if (a < xAverage && b < yAverage) {
    return color.purple
  } else if (a < xAverage && b > yAverage) {
    return color.red
  } else if (a > xAverage && b > yAverage) {
    return color.yello
  } else {
    return color.other
  }
}

export const getChartColor = (val, pageType) => {
  let color = '#E20707'
  switch (true) {
    case val >= 60 && val < 80 && pageType === 'NPS':
      color = '#FFB900';
      break;
    case val >= 80:
      color = '#0FC883';
      break;
    default:
      break;
  }

  return color;
}

const n = 1000 * 60 * 60 * 24 - 1

export const configField = {
  NPS: {
    linkPWDToken: {
      overviewResourceId: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZUlkIjoiNjQ0ZmUyNGEtNTUxZS0xMWVkLWJhNTYtNTM0N2QxZWJhMjIwIiwidXNlcklkIjoxfQ._VeOmL_h6buZ_IsxgGuwC0I3S-6zsyCT_4OLJ0vuwrQ',
      statisticsId: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZUlkIjoiMDk5ZTRkZWMtNTUxZi0xMWVkLWJhNTYtNTM0N2QxZWJhMjIwIiwidXNlcklkIjoxfQ.EPQKybvUB4fRJ_m2X3Ph8_c0dQGMj6YwbBMOD1V-dI8',
      analysisId: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZUlkIjoiMTk1Nzc3NWYtNTUzYS0xMWVkLWJhNTYtNTM0N2QxZWJhMjIwIiwidXNlcklkIjoxfQ.MPUeGEcRbO5KdhQfhVzOCms9Zh4AHgJ3N6KFZSvK3vc',
    },
    overviewResourceId: '644fe24a-551e-11ed-ba56-5347d1eba220', // 评分概览模版 ID
    statisticsId: '099e4dec-551f-11ed-ba56-5347d1eba220', // 排名统计
    analysisId: '1957775f-553a-11ed-ba56-5347d1eba220',
    chartID: {
      // 评分概览模版
      overviewResourceId: {
        chart1: '4ccaa6ff-2a1c-4ece-86f1-08c89c678546', // 图一 - 仪表盘
        chart2: '09ef50bc-93d4-43c8-a54d-00ceb29886a8', // 图二 - 得分
        chart3: '696057cb-a4c0-4a6c-b162-c8a4c8b6e54d', // 图三 - 分值柱状图
        chart4: {
          week: '9e93f251-c255-42df-a8bd-e83223522fbb',
          month: '800e9f1c-9325-4ab8-a96d-e01429b4b1ed',
          quarter: 'ee43cff5-cef3-42e3-944f-3bbb5cae61b5',
          year: 'f9b91ff0-3ea4-48d7-a228-e2dc86aeeeee',
        }, // 图四 - 变化趋势
      },
      statisticsId: {
        chart5: {
          region: 'a0837f53-0ec1-4294-890a-519ac4363c04',
          city: '1a24b9ce-f0fc-427b-8ea1-0f9dc90e5fce',
          shop: '09da40dc-9c88-4ce8-a22d-9f2da3d3d37f',
        },
        chart6: {
          city: 'c351f879-905d-42e9-8f1d-66d44b226639',
          shop: 'ddf8b587-465c-496a-a12c-5cb17fe1b18c',
        },
        chart7: {
          region: 'da66bf0a-0698-48b0-923c-fee1a0dbf847',
          city: 'b4f14ab3-14b8-4c2c-a2d9-da834133a4f5',
          shop: '125e7dd5-e996-4aa1-8a4c-dab06764c6df',
        },
        chart13: '56fbe27e-8cc6-44c4-b9af-6e21e8acae82',
      },
      analysisId: {
        chart8: {
          level1: '724c34c8-6c84-4310-92ba-ad524b8c189c',
          level2: 'b677d10d-0e06-45a5-9ed4-4fedb7c2d3b5',
        },
        chart9: {
          level1: '5a376048-e50a-4efc-85fd-4663a4a76a29',
          level2: '713bf8ab-b16b-45d4-9d44-3ba5b2ecc5f5',
        },
        chart10: {
          level1: '7c39fe45-c978-4542-9688-cbb80c4dfb6e',
          level2: '6cc744d1-d860-43d1-a1db-1605107b686a',
        }, // 散点图
        chart11: '89334827-a3a1-4527-a135-82d9627e107f', // 词云
        chart12: '773c2adb-ebf1-4bbf-8782-7f0234e1521b', // 词云列表
      }
    },
    payload: {
      filter: [],
      // "linkageFilters":[],
      "drill": [],
      "resultCount": 1000,
      "resultMode": "all",
      "queryFrom": "panel",
      "cache": true
    },
    filter: {
      project: {
        fieldId: '3fffc391-f3d7-4a3b-8fe0-ed6ee41ca2d9',
        operator: "eq",
        isTree: false,
        value: ''
      },
      time: {
        fieldId: 'b0447771-9617-41ae-b1bf-44ddbe358c69',
        value: process.env.VUE_APP_ENVIRONMENT === 'demo' ? getLastYear() : [getStartDayOfMonth(0).getTime(), getEndDayOfMonth(0).getTime() + n],
        operator: "between",
        isTree: false,
      },
      region: {
        fieldId: "360a0832-0f5a-4c0c-a986-cce453533a42,6db9f291-d7be-4543-bc1c-1ac0b3450160,8f42249e-e920-4f0a-8fad-4a3e23c7b843,5344f094-eb7d-4996-9f85-dd896e2a254a",
        operator: "in",
        isTree: true,
      },
      category: {
        fieldId: '27d1829e-0895-4953-b1aa-4348e464c3ce',
        operator: "in",
        isTree: false,
      },
      grantType: {
        fieldId: '82b15652-2398-4425-b2be-74f5bd51f813',
        operator: "in",
        isTree: false,
      },
    },
    experience: {
      operator: 'in',
      isTree: true,
      fieldId: '0f707411-e352-4564-adfc-b9a4ee6d5a98,b6966348-4bb6-4f3f-a181-f7a502dd8bd3',
      bar: [],
      matrix: [],
    },
    analysisFieldId: {
      project: '4cacc805-a09c-4620-aaf8-74f68573c08a,901548eb-b55d-471a-b4cd-dacc2a012176',
      category: '7882ae4a-f06b-4062-8e2e-675d7130344a,b8c7f462-1409-4c0b-b6c9-9b5055cab3f2',
      grantType: '9f740864-a156-4da0-823c-48b9b0363762,4ff2f89e-2d83-417e-b979-98bc18be961f',
      region: '59c5bc1d-307f-4dc4-a619-263a9a05b449,d8042d40-a7fe-4fac-a279-1bd1233efe86,3eefc585-da13-41d0-a326-93891dcbfd8b,ffff4f00-c096-42ab-9019-48536feafb94',
      time: '5869013d-a590-4704-ba9a-47c2a5292429,866ff610-6022-40ad-9e96-ed18b1938f71',
      region2: '86a46887-e18d-4006-9b58-54768e9eb72c,dd09425b-a06c-4ab4-b214-d4235524581f,fea3378e-bc35-4e2f-b43b-901cd07cbc09,b6e3c9e4-1b85-48ad-8d07-7c460f4ef5d9'
    },
    wordFieldId: {
      time: 'c6c70e5b-cfea-4fab-bd75-abdcb834e73f',
      region: '2c618c93-f08f-4dde-9d4b-4bcb7b18c09b,7d024c96-e5dd-4672-b0ff-5552e92036f8,f6f82214-0fe2-4036-8f80-7faf15f39d07,f22bbadd-f1d9-4216-9f90-81ae91c57ccd',
      project: 'f7befadd-6752-4acf-ac78-74f647fa9113',
      category: '183089fe-12a0-4f24-900c-b9fd98e74a09',
      grantType: 'e87a1d65-0138-4104-b858-6b7e92033f4c',
    },
    // 仪表盘补充数据
    npsData: {
      yb: '35562b26-53db-4761-9c78-05ea925daeaa',
    },
  },
  CSI: {
    linkPWDToken: {
      overviewResourceId: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZUlkIjoiZjY2YmMzMGYtNmI0Yi0xMWVkLWI3ZmItZjMyYzk3YTE3N2E1IiwidXNlcklkIjoxfQ.ojLC_326eGmdiSyobE_hCIxUM3Jdqb38JxZyV2t-EIo',
      statisticsId: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZUlkIjoiZjY2YmMzMGYtNmI0Yi0xMWVkLWI3ZmItZjMyYzk3YTE3N2E1IiwidXNlcklkIjoxfQ.ojLC_326eGmdiSyobE_hCIxUM3Jdqb38JxZyV2t-EIo',
      analysisId: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZUlkIjoiMTlkMmZkOTQtNmJhZi0xMWVkLWI3ZmItZjMyYzk3YTE3N2E1IiwidXNlcklkIjoxfQ.MYHyUBsVQ8AI-ruLYt7OJwgiKbfAAN0kuQTyWLSRu2U',
    },
    overviewResourceId: 'f66bc30f-6b4b-11ed-b7fb-f32c97a177a5', // 评分概览模版 ID
    statisticsId: '832e9496-6baf-11ed-b7fb-f32c97a177a5', // 排名统计
    analysisId: '19d2fd94-6baf-11ed-b7fb-f32c97a177a5',
    chartID: {
      // 评分概览模版
      overviewResourceId: {
        chart1: 'f6702fe0-6b4b-11ed-b7fb-f32c97a177a5', // 图一 - 仪表盘
        chart2: 'f672ef02-6b4b-11ed-b7fb-f32c97a177a5', // 图二 - 得分
        chart3: 'f6738b44-6b4b-11ed-b7fb-f32c97a177a5', // 图三 - 分值柱状图
        chart4: {
          week: 'f67511e8-6b4b-11ed-b7fb-f32c97a177a5',
          month: 'f684c97e-6b4b-11ed-b7fb-f32c97a177a5',
          quarter: 'f686c550-6b4b-11ed-b7fb-f32c97a177a5',
          year: 'f687afb2-6b4b-11ed-b7fb-f32c97a177a5',
        }, // 图四 - 变化趋势
      },
      statisticsId: {
        chart5: {
          region: '832e9496-6baf-11ed-b7fb-f32c97a177a5',
          city: '8330de8c-6baf-11ed-b7fb-f32c97a177a5',
          shop: '8333287e-6baf-11ed-b7fb-f32c97a177a5',
        },
        chart6: {
          city: '83301b3a-6baf-11ed-b7fb-f32c97a177a5',
          shop: '8333ebd0-6baf-11ed-b7fb-f32c97a177a5',
        },
        chart7: {
          region: '832f57e8-6baf-11ed-b7fb-f32c97a177a5',
          city: '83348812-6baf-11ed-b7fb-f32c97a177a5',
          shop: '83354b64-6baf-11ed-b7fb-f32c97a177a5',
        },
        chart13: '83360eb6-6baf-11ed-b7fb-f32c97a177a5',
      },
      analysisId: {
        chart8: {
          level1: '44244e87-42ca-4cc0-94ac-f411f038d524',
          level2: 'e854b437-2168-49d8-957e-f6ee4cf983b2',
        },
        chart9: {
          level1: '28a26979-ba04-47d1-ab2d-6c88be5c5ced',
          level2: '7b752dab-4e3c-42e5-9c69-48496e6cbbf8',
        },
        chart10: {
          level1: 'cf03baca-1d8f-4da1-b5fa-3430abf518d4',
          level2: 'a48255e5-9c56-41e4-8a98-e838c6392f43',
        }, // 散点图
        chart11: '1a04e32b-6baf-11ed-b7fb-f32c97a177a5', // 词云
        chart12: '1a05a67d-6baf-11ed-b7fb-f32c97a177a5', // 词云列表
      }
    },
    payload: {
      filter: [],
      "drill": [],
      "resultCount": 1000,
      "resultMode": "all",
      "queryFrom": "panel",
      "cache": true
    },
    filter: {
      project: {
        fieldId: '482274c5-355f-4837-9f89-f4771ba87eda',
        operator: "eq",
        isTree: false,
        value: ''
      },
      time: {
        fieldId: '322783d4-93f6-4ade-a831-13cd817853b2',
        value: process.env.VUE_APP_ENVIRONMENT === 'demo' ? getLastYear() : [getStartDayOfMonth(0).getTime(), getEndDayOfMonth(0).getTime() + n],
        operator: "between",
        isTree: false,
      },
      region: {
        fieldId: "6db5ccf2-213b-46b5-95f2-af55c61f9f18,7dd389d9-9ac1-444e-8d9f-7840b614c9f5,6ee33978-26ab-489c-a1ca-dfe1a5d64c5c,c6cb9a25-3637-41a2-a52d-a6ca1e89f719",
        operator: "in",
        isTree: true,
      },
      category: {
        fieldId: '0ec3511c-06a8-4c2a-8b3a-c1dc42eba6c5',
        operator: "in",
        isTree: false,
      },
      grantType: {
        fieldId: '14427ce6-75c0-45c2-a797-a00067b1a303',
        operator: "in",
        isTree: false,
      },
    },
    experience: {
      operator: 'in',
      isTree: true,
      fieldId: '2f9aa5eb-2453-42ab-9376-8de67ecce885,c611360a-e6ff-4def-bd16-d85788370717',
      bar: [],
      matrix: [],
    },
    analysisFieldId: {
      project: 'f06bdfde-f9d1-4feb-9d61-a3378a622097,f7befadd-6752-4acf-ac78-74f647fa9113,f58f8eff-f97b-4040-8781-03345dc7fee1',
      category: 'e0ca89f5-9e11-47e7-8aa8-557f08e12d0d,183089fe-12a0-4f24-900c-b9fd98e74a09,93e181cc-609d-4b52-bff3-2f76fde05e94',
      grantType: '60972879-03ec-4123-83f0-b022cef8a68f,e87a1d65-0138-4104-b858-6b7e92033f4c,5b220b8c-22ce-4d7d-8e6a-90f788826014',
      region: 'f17c7d02-c910-4f54-ad3d-2b8bdb26b734,078ab768-2f87-42e2-af21-a2278d2faf07,19c45218-4ddf-4cb2-bc61-dee5e60d18a3,73ac5212-8d0c-4338-a1af-6937ed347012',
      region2: '9e25ea13-aab7-4b2c-81b9-6307a34c1ee2,2d9cd241-086c-4d8f-88fb-a6fffbb15e14,c78877ac-85a6-4ce0-b728-93693fccb9fb,33bc8e63-8374-4138-8474-43579c55f012',
      time: 'a4b92b20-75ac-4a46-867f-947840de895d,c6c70e5b-cfea-4fab-bd75-abdcb834e73f,f11c8f31-04f4-4042-b7b5-3378e7359fc7',
    },
    wordFieldId: {
      time: 'c6c70e5b-cfea-4fab-bd75-abdcb834e73f',
      region: '2c618c93-f08f-4dde-9d4b-4bcb7b18c09b,7d024c96-e5dd-4672-b0ff-5552e92036f8,f6f82214-0fe2-4036-8f80-7faf15f39d07,f22bbadd-f1d9-4216-9f90-81ae91c57ccd',
      project: 'f7befadd-6752-4acf-ac78-74f647fa9113',
      category: '183089fe-12a0-4f24-900c-b9fd98e74a09',
      grantType: 'e87a1d65-0138-4104-b858-6b7e92033f4c',
    },
    // 仪表盘补充数据
    npsData: {
      yb: 'f6744e96-6b4b-11ed-b7fb-f32c97a177a5',
    },
  }
}