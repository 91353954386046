var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "16px" } },
    [
      _c("el-form-item", { attrs: { label: "消息实例" } }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "space-between",
              "flex-wrap": "nowrap",
            },
          },
          [
            _c(
              "el-select",
              {
                on: { change: _vm.updateTaskMessage },
                model: {
                  value: _vm.bindMessageId,
                  callback: function ($$v) {
                    _vm.bindMessageId = $$v
                  },
                  expression: "bindMessageId",
                },
              },
              _vm._l(Object.keys(_vm.messageMap), function (id) {
                return _c("el-option", {
                  key: id,
                  attrs: { value: id, label: _vm.messageMap[id] },
                })
              }),
              1
            ),
            _c("el-button", {
              staticStyle: { "margin-left": "8px" },
              attrs: { size: "mini", type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.openMessageModel },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.messageModelVisible,
            "close-on-click-modal": false,
            title: "创建新消息",
            width: "400px",
            "append-to-body": "",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.messageModelVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.newMessageForm,
                size: "mini",
                "label-width": "90px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "消息ID" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.newMessageForm.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.newMessageForm, "id", $$v)
                      },
                      expression: "newMessageForm.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "消息名称" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.newMessageForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.newMessageForm, "name", $$v)
                      },
                      expression: "newMessageForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.createNewMessage },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }