<template>
    <el-dropdown
        @visible-change="(val) => visible = val"
        class="gy-avatar-container right-menu-item hover-effect"
        trigger="hover"
        placement="top"
    >
        <div class="avatar-wrapper">
          <img :src="avatar" class="user-avatar">
          <span v-if="nickname" class="user-nickname">{{ nickname }}</span>
          <i v-if="visible" class="el-icon-arrow-up downIcon" />
          <i v-else class="el-icon-arrow-down downIcon" />
        </div>
        <el-dropdown-menu slot="dropdown" class="gy-avatar-menu">
          <router-link to="/user/profile">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <!-- <el-dropdown-item v-if="$theme === 'gy'" @click.native="toHelp">帮助中心</el-dropdown-item> -->
          <el-dropdown-item @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPath } from "@/utils/ruoyi";
export default {
    data() {
        return {
            visible: ''
        }
    },
    computed: {
        ...mapGetters([
            'sidebar',
            'avatar',
            'nickname',
            'device'
        ]),
    },
    methods: {
        async logout() {
            this.$modal.confirm('确定注销并退出系统吗？', '提示').then(() => {
                this.$store.dispatch('LogOut').then(() => {
                location.href = getPath('/index');
                })
            }).catch(() => { });
        },
        toHelp() {
            window.open('https://wvbrty8gbrg.feishu.cn/docx/ANFgduT4JodloExuMGLcfXXAnQg?from=from_copylink', '_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
.gy-avatar-container {
    // margin-right: 30px;
    height: 60px;
    border-top: 1px solid #ECECF1;
    display: flex;
    align-items: center;
    padding-left: 20px;
    .avatar-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        .user-avatar {
            cursor: pointer;
            width: 28px;
            height: 28px;
            border-radius: 50%;
        }

        .user-slogan {
            margin-left: 11px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }

        .user-nickname {
            margin-left: 8px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            color: #1D2129;
            cursor: default;
            font-weight: 500;
        }

        .downIcon {
            margin-left: 5px;
            font-size: 14px;
            color: #333333;
        }

        .el-icon-caret-bottom {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: 25px;
            font-size: 12px;
        }
    }
}
.gy-avatar-menu {
    overflow: hidden;
}
</style>