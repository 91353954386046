<template>
  <div :class="`navbar ${$theme ? $theme + '-navbar' : ''}`">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
      @toggleClick="toggleSideBar" />

    <!-- <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav"/> -->
    <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav" />

    <div class="right-menu">
      <el-dropdown @visible-change="(val) => visible = val" class="avatar-container right-menu-item hover-effect" trigger="hover">
        <div class="avatar-wrapper">
          <img :src="avatar" class="user-avatar">
          <span class="user-slogan">欢迎!</span>
          <span v-if="nickname" class="user-nickname">{{ nickname }}</span>
          <i v-if="visible" class="el-icon-arrow-up downIcon" />
          <i v-else class="el-icon-arrow-down downIcon" />
        </div>
        <el-dropdown-menu slot="dropdown" class="el-dropdown-menu__box">
          <router-link to="/user/profile">
            <el-dropdown-item><svg-icon class="navBar_svg" icon-class="gerenzhongxin" />个人中心</el-dropdown-item>
          </router-link>
          <!-- <el-dropdown-item @click.native="setting = true">
            <span><svg-icon class="navBar_svg" icon-class="xitongxiaoxi" />系统设置</span>
          </el-dropdown-item> -->
          <el-dropdown-item @click.native="toMessage()">
            <span><svg-icon class="navBar_svg" icon-class="xitongxiaoxi" />系统消息</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            <span><svg-icon class="navBar_svg" icon-class="tuichudenglu" />退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
import RuoYiGit from '@/components/RuoYi/Git'
import RuoYiDoc from '@/components/RuoYi/Doc'
import { getPath } from "@/utils/ruoyi";
import { getCemToken } from '@/utils/auth'

export default {
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    RuoYiGit,
    RuoYiDoc
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'nickname',
      'device'
    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav
      }
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    toMessage() {
      this.$router.push({
        path: '/system/messageManagement',
        query: {
          pageType: 'yonghutiyanguanlixitong',
        }
      })
    },
    async logout() {
      this.$modal.confirm('确定注销并退出系统吗？', '提示').then(() => {
        if (getCemToken()) {
          this.$store.dispatch('PlatformLogout').then(res => {
            location.href = res?.data ? res.data : 'https://cdp.gacmotor.com'
          })

        } else {
          this.$store.dispatch('LogOut').then(() => {
            location.href = getPath('/index');
          })
        }
      }).catch(() => { });
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: $base-header-height;
  overflow: hidden;
  position: relative;
  background: #fff;
  border-bottom: 1px solid $base-color-gray;

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: $base-header-height;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        padding: 0 12px;
        border-radius: 5px;
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        .user-slogan {
          margin-left: 11px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }

        .user-nickname {
          margin-left: 5px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0278FF;
        }

        .downIcon {
          margin-left: 5px;
          font-size: 16px;
          color: #333333;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }

  &.gq-navbar {
    border-bottom: unset;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  }
}

.navBar_svg {
  width: 16px !important;
  height: 16px !important;
  padding: 0 !important;
  margin-right: 8px;
}

.el-dropdown-menu__box {

  &::v-deep {
    .el-dropdown-menu__item>span {
      display: flex;
      align-items: center;
    }
  }
}
</style>
