<template>
  <LayoutCem v-if="isGy"/>
  <Layout v-else/>
</template>

<script>
import RightPanel from '@/components/RightPanel'
import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'
import { getDevice, getHeaderTitle } from '@/utils/auth'
import { getPath } from "@/utils/ruoyi";
import Layout from './Layout.vue'
import LayoutCem from './LayoutCem.vue'
export default {
  components: {
    Layout,
    LayoutCem
  },
  data() {
    return {
    }
  },
  computed: {
    isGy () {
      return this.$theme === 'gy'
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/mixin.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.header_title {
  height: 56px;
  line-height: 56px;
  display: flex;
  background: #3875C5;

  .svg_icons {
    width: 3rem;
    text-align: center;
    padding-top: 0.4rem;
  }

  .title {
    width: calc(100% - 3rem);
    text-align: center;
    font-size: 1.5rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    padding-right: 3rem;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$base-sidebar-width});
  transition: width 0.28s;

}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.sidebarHide .fixed-header {
  width: calc(100%);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
