<template>
	<div :class="`main ${$theme + '-main'}`">
		<div class="content">
			<div v-if="titleConf" class="title-wrap flex">
				<div class="flex">
          <span v-if="titleConf.goback" class="back-icon" @click="goback">
            <i  class="el-icon-arrow-left icon" ></i>
          </span>
					
					<h3 class="font-header">
						{{ titleConf.title }}
					</h3>
				</div>
				
				<el-button 
				v-if="titleConf.btnVisible" 
				:icon="titleConf.btnIcon || ''" 
				class="btn"
				@click="handelClick"
				type="primary"
				>{{ titleConf.btnText }}</el-button>
        <slot name="rightBtn" v-if="titleConf.rightSlot"></slot>
			</div>
			<el-divider v-if="$theme === 'gq' && showHeaderLine" style="margin-top: 20px;"></el-divider>
			<slot></slot>
		</div>
	</div>
</template>
<script>
  export default {
    props: ['titleConf', 'showHeaderLine'],
    methods: {
      handelClick() {
        this.$emit('onClickHeaderBtn')
      },
      goback() {
        if (this.titleConf.toPath) {
          this.$router.push(this.titleConf.toPath)
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
</script>
<style lang="scss" scoped="">
.main {
  width: 100%;
  min-height: calc(100vh - #{$base-header-height});
  background-color: #f8f8f8;
  padding: 17px 24px 24px;
  // display: flex;
  color: $font-color;
  & > .content {
    background-color: #fff;
    border-radius: $base-border-radius;
    padding: 20px 24px;
    height: 100%;
    min-height: calc(100vh - #{$base-header-height} - 41px);
  }

  .title-wrap {
    justify-content: space-between;
    
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .back-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    border-radius: $base-btn-border-radius;
    margin-right: 11px;
    margin-left: -8px;
    justify-content: space-around;

    &:hover {
      background-color: #F2F2F2;
    }

    .icon {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .btn {
    width: auto;
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;

    &:hover {
      opacity: 1;
    }
  }
}

.gq-main {
  padding: 16px;
  & > .content {
    background-color: #fff;
    height: 100%;
    min-height: calc(100vh - #{$base-header-height} - 41px);
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1);
	  padding: 0;
    
    .title-wrap + .el-divider--horizontal {
      margin-top: 20px;
    }
  }

  .title-wrap {
    justify-content: space-between;
    padding: 20px 24px 0;
  }
}

</style>