// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n$base-menu-color:hsla(0,0%,100%,.65);\n$base-menu-color-active:#fff;\n$base-menu-background:#001529;\n$base-logo-title-color: #ffffff;\n\n$base-menu-light-color:rgba(0,0,0,.70);\n$base-menu-light-background:#ffffff;\n$base-logo-light-title-color: #001529;\n\n$base-sub-menu-background:#000c17;\n$base-sub-menu-hover:#001528;\n*/", ""]);
// Exports
exports.locals = {
	"menuColor": "#fff",
	"menuLightColor": "rgba(0, 0, 0, 0.7)",
	"menuColorActive": "#1D2129",
	"menuBackground": "#304156",
	"menuLightBackground": "#ffffff",
	"subMenuBackground": "#1f2d3d",
	"subMenuHover": "#fff",
	"sideBarWidth": "200px",
	"logoTitleColor": "#ffffff",
	"logoLightTitleColor": "#001529",
	"baseTextColor": "#111111",
	"baseThemeColor": "#D8302D",
	"baseThemeColor2": "#5F8BFC",
	"baseThemeBackgroundColor": "#F8F8F9",
	"echartsBarIconColor1": "#5F8BFC",
	"echartsBarIconColor2": "#66C4FA",
	"echartsBarIconColor3": "#FFC149",
	"echartsLineIconColor1": "#FF6484",
	"echartsLineIconColor2": "#FF8940",
	"echartsBorderColor": "rgba(2, 120, 255, 0.2)",
	"baseBorderColor": "#EBEEF5",
	"echartsXLabelColor": "#666"
};
module.exports = exports;
