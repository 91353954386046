import request from '@/utils/request'
// 获取实体列表
export function getModelList(data) {
    return request({
        url: `/journey/model/list`,
        method: 'post',
        data
    })
}

// 编辑实体
export function updateModel(data) {
    return request({
        url: `/journey/model/update`,
        method: 'put',
        data
    })
}

// 创建实体
export function createModel(data) {
    return request({
        url: `/journey/model/add`,
        method: 'post',
        data
    })
}
// 根据实体id 删除实体
export function deleteModelbyId(id) {
    return request({
        url: `/journey/model/delete?id=${id}`,
        method: 'delete',
    })
}

// 根据实体id 获取实体字段列表
export function getFieldList(id) {
    return request({
        url: `/journey/model/field/list?modelId=${id}`,
        method: 'post',
    })
}
// 添加实体字段
export function createField (data) {
    return request({
        url: `/journey/model/field/add`,
        method: 'post',
        data
    })
}
// 修改实体字段
export function updateField (data) {
    return request({
        url: `/journey/model/field/update`,
        method: 'put',
        data
    })
}
// 获取实体字值列表
export function getFieldValueList (id) {
    return request({
        url: `/journey/model/field-value/list?labelId=${id}`,
        method: 'post',
    })
}
// 添加实体字段值
export function createFieldValue (data) {
    return request({
        url: `/journey/model/field-value/add`,
        method: 'post',
        data
    })
}
// 修改实体字段值
export function updateFieldValue (data) {
    return request({
        url: `/journey/model/field-value/update`,
        method: 'put',
        data
    })
}
// 根据实体字段id和值code删除值
export function deleteFieldValueByCode ({ labelId, labelValueCode }) {
    console.log(labelId, labelValueCode )
    return request({
        url: `/journey/model/field-value/delete?labelId=${labelId}&labelValueCode=${labelValueCode}`,
        method: 'delete',
    })
}
// 根据实体字段id删除实体字段
export function deleteFieldById (labelId) {
    return request({
        url: `/journey/model/field/delete?labelId=${labelId}`,
        method: 'delete',
    })
}
// 获取实体明细列表
export function getModelDetailList(data) {
    return request({
        url: `/journey/model-detail/list`,
        method: 'post',
        data
    })
}

// 导出
export function downloadTemp(modelId) {
    return request({
        url: `/journey/model/export-excel?modelId=${modelId}`,
        method: 'get',
        responseType:'blob',
    })
}

// 导入
export function importData({ data, modelId }) {
    return request({
        url: `/journey/model-detail/import-excel?modelId=${modelId}`,
        method: 'post',
        headers: {
            'content-type': 'multipart/form-data'
        },
        data
    })
}

// 根据id获取实体明细
export function getModelDetailById(modelId) {
    return request({
        url: `/journey/model/model-detail?modelId=${modelId}`,
        method: 'get',
    })
}
// 创建实体明细
export function createModelDetail(data) {
    return request({
        url: `/journey/model-detail/add`,
        method: 'post',
        data
    })
}
// 编辑实体明细
export function updateModelDetail(data) {
    return request({
        url: `/journey/model-detail/update`,
        method: 'put',
        data
    })
}
// 根据实体明细id删除实体明细
export function deleteModelDetail(codeID) {
    return request({
        url: `/journey/model-detail/delete?codeID=${codeID}`,
        method: 'delete',
    })
}
export function exportSupplement(data) {
    return request({
        url: `/journey/model/supplement-export-excel`,
        method: 'post',
        responseType:'blob',
        data
    })
}

// 导入
export function importSupplementData({ data, modelId }, onUploadProgress) {
    return request({
        url: `/journey/model-detail/supplement-import-excel?modelId=${modelId}`,
        method: 'post',
        headers: {
            'content-type': 'multipart/form-data'
        },
        data
    })
}

export function deleteModelDetailBatch(codeIDs) {
    return request({
        url: `/journey/model-detail/batch-delete?codeIDs=${codeIDs}`,
        method: 'delete',
    })
}
export function exportModelDetailById (modelId) {
    return request({
        url: `/journey/model-detail/export-excel?modelId=${modelId}`,
        method: 'get',
        responseType:'blob',
    })
}
export function getModelTree() {
    return request({
        url: `/journey/model/tree`,
        method: 'get',
    })
}
