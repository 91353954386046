import { getCarBrandList } from '@/api/cem/carBrand.js'
const state = {
    list: [],
    loading: false,
}
const mutations = {
    UPDATE_STATE: (state, { key = '', data = null }) => {
        state[key] = data;
    },
}

const actions = {
    async getCarBrandList({ commit }, params = {}) {
        commit('UPDATE_STATE',  { key: 'loading', data: true })
        const res = await getCarBrandList(params)
        commit('UPDATE_STATE',  { key: 'loading', data: false })
        if (res.code === 200) {
            commit('UPDATE_STATE',  { key: 'list', data: res.data })
        }
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  