import request from '@/utils/request'
// 获取标签列表
export function getMetricsLibraryList (data) {
    return request({
        url: `/journey/metrics-library/list`,
        method: 'post',
        data
    })
}

// 创建指标库
export function createMetricsLibrary(data) {
    return request({
        url: `/journey/metrics-library/add`,
        method: 'post',
        data
    })
}

// 修改指标库
export function updateMetricsLibrary(data) {
    return request({
        url: `/journey/metrics-library/update`,
        method: 'put',
        data
    })
}

// 删除指标库
export function deleteMetricsLibrary(params) {
    return request({
        url: `/journey/metrics-library/delete`,
        method: 'delete',
        params
    })
}

export function getMetricsLibraryDetailById (params) {
    return request({
        url: `/journey/metrics-library/get-detail`,
        method: 'get',
        params
    })
}
export function getMetricsExpointList (params) {
    return request({
        url: `/journey/metrics/expoint/get-metric-tree`,
        method: 'post',
        params
    })
}
export function getMetricStructureTree (params) {
    return request({
        url: `/journey/metrics-library/get-metric-structure-tree`,
        method: 'post',
        params
    })
}