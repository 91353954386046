import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
import settings from './modules/settings'
import chart from './modules/chart'
import dict from './modules/dict'
import application from './modules/application'
import nps from './modules/nps'
import socialMedia from './modules/socialMedia'
import indicatorLibrary from './modules/indicatorLibrary'
import vehicleModelLibrary from './modules/vehicleModelLibrary';
import journeyMap from './modules/journeyMap'
import model from './modules/model'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    tagsView,
    permission,
    settings,
    dict,
    chart,
    application,
    nps,
    socialMedia,
    indicatorLibrary,
    vehicleModelLibrary,
    // cem
    journeyMap,
    model
  },
  getters
})

export default store
