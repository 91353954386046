import moment from 'moment'
export const configFieId = {
  self: {
    templateId: '48e2bdb7-7453-11ed-9b8e-c56391bdc6fd',
    linkPWDToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZUlkIjoiNDhlMmJkYjctNzQ1My0xMWVkLTliOGUtYzU2MzkxYmRjNmZkIiwidXNlcklkIjoxfQ.GLzWqmlEYuaYtgmM_hveKa_UiWicpdsH4YBK_gZ9vFY',
    chart1: {
      tm: '6e388a55-7394-467f-82ca-cd1eba7f0939',
      jd: 'acb4e2b5-a2b7-48d6-8b9c-6325eda7e857',
      dzdp: 'b2d58bfd-ebb4-4670-96b7-6f145de6e428',
    },
    chart2: {
      tm: 'e4749388-7411-4abb-8d0a-96c323cc374f',
      dzdp: '03511d52-b7dd-4cd8-8d96-3dd494aa6201',
      jd: '55d46fe7-744b-4e18-b161-a98abc3d4af4',
      md: '5dd3cfbf-1ff9-464c-ab60-adaf94c02fa7'
    },
    chart3: '7b3344e4-cd9f-4649-8926-d1c457fa27f5',
    chart4: '64985c1b-1f17-42f0-a7dd-616fbaa28ef2',
    chart5: '734a64ef-2679-4b29-8b2f-5ce1d230d2d0',
    chart6: 'ae357e3d-e8a2-49bb-96f0-81b0ee39803c',
    chart7: '04eefa89-1691-4e31-959e-51e05c688c52',
    chart8: 'fff4ed92-e509-454f-b235-a5cfa3a96449',
    chart9: 'ded1a71d-dc6d-414c-b017-28afede656cb',
    chart10: {
      all: '7de42d27-1b32-4599-883f-b4214e4211e2',
      dzdp: '1b983d81-f7d1-4c0b-baea-1455d17e8b62',
      tm: 'e81c965e-5b8f-4b2a-a3a4-40c1df3870cf',
      jd: 'ab3855e0-7792-4a2c-ba1a-be7d5428e565',
    },
    rankingDetailList: 'ee696702-b29e-4206-aa53-8d2f84a686b8',
    chart11: {
      all: '0b2edf9c-e5de-4092-828e-d941130e4144',
      dzdp: 'f5d91e96-c498-4080-b0af-9d915212b0ef',
      tm: '9a1532e3-eff2-4862-9688-7fb29047a813',
      jd: 'dba98729-933a-45b7-a6ee-a03c4c1d36fe',
    },
    payload: {
      filter:[],
      "drill":[],
      "resultCount": 1000,
      "resultMode": "all",
      "queryFrom": "panel",
      "cache": true
    },
    filterData: { // 存放获取的筛选数据
      regionList: [], // 所属大区
      cityList: [], // 所属城市
      userList: [], // 用户
      storeList: [], // 所属门店
      storeTypeList: [], // 店铺类型
      tradeModelList: [], // 经营类型
      storeList: [], // 所属门店
    },
    filter: {
      time: {
        fieldId:'829aecf8-d947-455a-84d6-2fdabb1c7af9',
        operator: "eq",
        isTree: false,
      },
      region: {
        fieldId: '2aeb1102-8d63-44a8-8eb9-9663d237557c',
        operator: "in",
        isTree: false,
      },
      city: {
        fieldId: '38d03b48-b623-42bc-81df-f98b24e8d650,9d03b88b-81bb-4e3b-8b27-5c5060ada3ef',
        operator: "in",
        isTree: true,
      },
      tradeModel: {
        fieldId: '78267539-f4dd-4943-b492-1267513f32bb',
        operator: "in",
        isTree: false,
      },
      storeType: {
        fieldId: 'e67d0436-b8ab-41ad-90e7-450401c62419',
        operator: "in",
        isTree: false,
      },
      user: {
        fieldId: '63319d0a-42fc-4837-9a67-15392899fd4d',
        operator: "in",
        isTree: false,
      },
      store: {
        fieldId: 'af5b1962-deab-4982-ac99-a6f1eb4eadf6',
        operator: "in",
        isTree: false,
      },
      date: {
        fieldId: '549ac508-6180-40d3-904c-7829b395667a',
        operator: "between",
        isTree: false,
      }
    }

  },
  competitor: {
    templateId: '10f4a940-7526-11ed-9b8e-c56391bdc6fd',
    linkPWDToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZUlkIjoiMTBmNGE5NDAtNzUyNi0xMWVkLTliOGUtYzU2MzkxYmRjNmZkIiwidXNlcklkIjoxfQ.ysk3q2JBDBEz_7HwQKOLih2GK-xTUXM9EnEm_5q46mo',
    payload: {
      filter:[],
      "drill":[],
      "resultCount": 1000,
      "resultMode": "all",
      "queryFrom": "panel",
      "cache": true
    },
    chart12: {
      dzdp: '44255257-d793-4b17-8514-b74249ec3f20',
      tm: 'bbfb31c8-30d3-4280-97b6-f8d05c64ac6b',
      jd: '10f5e041-7526-11ed-b202-0242ac160003',
    },
    chart13: {
      dzdp: '757ad2c9-c991-4e51-858c-0de0ce2da20d',
      tm: '4bff1e1f-5a01-4e20-a791-1be954da99e2',
      jd: '22d36497-2bd2-41f2-97e1-5f112cdd3e12',
    },
    chart10: '10f5e427-7526-11ed-b202-0242ac160003',
    chart11: '10f5e4a0-7526-11ed-b202-0242ac160003',
    chart14: {
      dzdp: '10f5e094-7526-11ed-b202-0242ac160003',
      tm: '560e8f80-e190-4e32-b3ff-17b9803d4820',
      jd: '20246818-54e5-483f-8e34-e0398e4644cf',
    },
    chartCar: {
      awt: '10f5e1a1-7526-11ed-b202-0242ac160003', // 阿维塔
      xiaopeng: '10f5e1ca-7526-11ed-b202-0242ac160003', // 小鹏
      aodi: '10f5e1f6-7526-11ed-b202-0242ac160003', // 奥迪
      weilai: '10f5e221-7526-11ed-b202-0242ac160003', // 蔚来
      tesila: 'ad97d83e-250d-4f3e-aa15-249137fba741', // 特斯拉
      lixiang: 'c16f978b-7397-4873-912a-3552b804a2d7', // 理想
      baoma: '2f575131-0b00-4467-a965-16feeaa54993', // 宝马
      zhiji: 'b61fd85a-fdb0-480f-8234-004767933066', // 智及
      benchi: 'a2b38900-74c3-4de2-a5fb-d5d4767f22e8', // 奔驰
      aito: 'cb520fd5-c725-4a20-839c-6693d07cea4d', // AITO
      jihu: '9bae1b6a-5940-411b-a4ef-3b4cbbd7a3e5', // 极狐
      jike: '9ac2773d-e425-433c-aebd-b34e6091a59f', // 极氪
    }
  }
}

export const filterFieId = {
  self: {
    region: '2aeb1102-8d63-44a8-8eb9-9663d237557c',
    city: ['38d03b48-b623-42bc-81df-f98b24e8d650', '9d03b88b-81bb-4e3b-8b27-5c5060ada3ef'],
    tradeModel: '78267539-f4dd-4943-b492-1267513f32bb',
    storeType: 'e67d0436-b8ab-41ad-90e7-450401c62419',
    user: '63319d0a-42fc-4837-9a67-15392899fd4d',
    store: 'af5b1962-deab-4982-ac99-a6f1eb4eadf6',
  },
  ranking: {
    region: '20e4c3a5-d1d8-408a-971b-2f4e53db91a4',
    city: '913e7631-69a6-4f87-a7d8-9ad511b27acd,9d3eb3ff-04f2-4cad-9cdc-e2a239ec29c4',
    storeType: 'b893c767-9136-4e36-99b6-b88229e484cc',
    tradeModel: '5e1ae2a8-2e76-4251-8487-7e1e9ac4e136',
    store: 'af5b1962-deab-4982-ac99-a6f1eb4eadf6',
    time: '3692c54e-65a1-4ddf-b135-f536f02c9358',
  },
  rankingList: {
    region: 'ed91637d-6d3e-4937-a54d-1dca0a4008d5',
    city: '9f89cd1a-43eb-4fd9-b37c-4b3977282933,b2307fd3-ad79-4b3f-80b2-3a91aaf84103',
    tradeModel: '5bd0fc7a-3a24-47f0-8dae-663ec7b868cc',
    date: '549ac508-6180-40d3-904c-7829b395667a',
  }
}

export const month_date = (times) => {
  const timeArr = times.split('/')|| [];
  if (timeArr.length) {
    let year = timeArr[0];
    const month = timeArr[1];
    const startDay = moment(`${year}-${month}`).startOf('months').format('YYYY-MM-DD')
    const endDay = moment(`${year}-${month}`).endOf('months').format('YYYY-MM-DD')
    return [startDay, endDay]
  } else {
    return []
  }
}
export const titleStyle = `font-size: 12px; color: #86909C; margin-bottom: 16px;`
export const lineStyle = `display: flex; justify-content: space-between; color: #4E5969; margin-top: 14px; font-size: 14px;`
export const wrapStyle = `width: 160px`
export const valueStyle = "color: #181818; font-size: 16px; font-weight: bolder;"
export const brandNameList = ['awt', 'xiaopeng', 'aodi', 'weilai', 'tesila', 'lixiang', 'baoma', 'zhiji', 'benchi', 'aito', 'jihu', 'jike']

export const carConfig = {
  awt: '阿维塔',
  xiaopeng: '小鹏',
  aodi: '奥迪',
  weilai: '蔚来',
  tesila: '特斯拉',
  lixiang: '理想',
  baoma: '宝马',
  zhiji: '智己',
  benchi: '奔驰',
  aito: 'AITO',
  jihu: '极狐',
  jike: '极氪',
}

export const typeMap = {
  dzdp: '大众点评',
  tm: '天猫',
  jd: '京东',
  tb: '同比',
  hb: '环比'
}