import { getExpointList, getAggregateList } from '@/api/journeyMap/metrics.js'
import { getMeasureList } from '@/api/journeyMap/expoint/measure.js'
import { getBasicList, getGroupList } from '@/api/journeyMap/expoint.js'
import { getRowTempList, getContentType }from '@/api/journeyMap/rowTemplate.js' 
import { getJourneyDetail, getJourneyTempById, getPictureList } from '@/api/journeyMap/journey'
const state = {
	addPointVisible: false,
	addTargetVisible: false,
	measureMap: {},
	measureList: [], // 度量列表
	measureListLoading: false, // 度量列表loading
	basicListLoading: false, // 基础体验点loading
	basicList: { total: 0, list: [] }, // 基础体验点列表
	illustrationVisible: false, // 
	groupList: [], // 分组列表
	groupListLoading: false, // 分组loading
	lineTempListLoading: false,
	lineTempList: [],
	expointList: { total: 0, list: [] },
	expointListLoading: false,
	journeyDetail: {},
	contentType: {
		list: [],
		map: {}
	},
	pictureList: [],
	aggregateListLoading: false,
	aggregateList: { total: 0, list: [] },
}

const mutations = {
	UPDATE_STATE: (state, { key = '', data = null }) => {
		state[key] = data;
	},
}

const actions = {
    updateState({ commit }, { key, data = null }) {
        commit('UPDATE_STATE', { key, data })
    },
	// 获取度量列表
	async getMeasureList({ commit }) {
		commit('UPDATE_STATE', { key: 'measureListLoading', data: true, })
		const res = await getMeasureList({ pageNum: -1 })
		commit('UPDATE_STATE', { key: 'measureListLoading', data: false, })
		if (res.code === 200) {
			const obj = {}
			const { list, total } = res.data
			if (total) {
				list.forEach(v => {
					obj[v.id] = v
					if (v.calculationCaliberModel?.caliberList) v.caliberList = v.calculationCaliberModel.caliberList
					if (v.calculationCaliberModel?.sections) v.sections = v.calculationCaliberModel.sections
				})
				commit('UPDATE_STATE', { key: 'measureMap', data: obj || {} })
			}
			commit('UPDATE_STATE', { key: 'measureList', data: list || [] })
		}
	},
	// 获取基础体验点
	async getBasicList({ commit }, { params = {} }) {
		commit('UPDATE_STATE', { key: 'basicListLoading', data: true, })
		const res = await getBasicList(params)
		commit('UPDATE_STATE', { key: 'basicListLoading', data: false, })
		if (res.code === 200) {
			const data = res.data || { list: [], total: 0 }
			data.list.forEach(v => {
				const arr = []
				for (let id in v.group) {
					arr.push({ id, name: v.group[id] })
				}

				v.groupList = arr
			})
			commit('UPDATE_STATE', { key: 'basicList', data })
		}

	},
	async getGroupList({ commit }, { params = {} } = {}) {
		const res = await getGroupList(params)
		if (res.code === 200) {
			const list = (res.data || []).map(v => {
				if (v.exPointVOList && v.exPointVOList.length) v.exPointVOList = v.exPointVOList.sort((a, b) => a.number - b.number)
				return { ...v, name: v.groupName }
			})
			commit('UPDATE_STATE', { key: 'groupList', data: list })
		}
	},
	async getLineTempList({ commit }) {
		commit('UPDATE_STATE', { key: 'lineTempListLoading', data: true, })
		const res = await getRowTempList()
		commit('UPDATE_STATE', { key: 'lineTempListLoading', data: false, })
		if (res.code === 200) {
			commit('UPDATE_STATE', { key: 'lineTempList', data: res.data || [] })
		}
	},
	async getExpointList({ commit }, { params }) {
		commit('UPDATE_STATE', { key: 'expointListLoading', data: true })
		const res = await getExpointList(params)
		commit('UPDATE_STATE', { key: 'expointListLoading', data: false })
		if (res.code === 200) {
			commit('UPDATE_STATE', { key: 'expointList', data: res.data || { list: [], total: 0 } })
		}
	},
	async getJourneyDetail({ commit }, { type, params, callback = () => {} }) {
		const fn = type === 1 ? getJourneyDetail : getJourneyTempById
		const obj = type === 1 ? params : { journeyTemplateId: params.id }
		const res = await fn(obj)

		if (res.code === 200) {
			commit('UPDATE_STATE', { data: res.data, key: 'journeyDetail' })
			callback(res.data)
		}
	},
	async getContentType({ commit }) {
		const res = await getContentType();
		if (res.code === 200) {
			const obj = {map: {}, list: []}
			res.data.forEach(v => {
				if (v.state) obj.list.push(v)
				obj.map[v.key] = v;
				obj.map[v.value] = v;
			})

			commit('UPDATE_STATE', { data: obj, key: 'contentType' })
		}
	},
	// 旅程系统图片接口
	async getPictureList({ commit }) {
		const res = await getPictureList();
		if (res.code === 200) {
			if (res.data.system && Object.keys(res.data.system).length) {
				const obj = res.data.system;
				let list = []
				for (let k in obj){
					if (obj[k] && obj[k].length) {
						obj[k].forEach(v => {
							v.name = v.name.split('.')[0]
							list.push(v)
						})
					}
				}
				commit('UPDATE_STATE', { data: list, key: 'pictureList' })
			}
		}
	},
	async getAggregateList({ commit }, { params }) {
		commit('UPDATE_STATE', { data: true, key: 'aggregateListLoading' })
		const res = await getAggregateList(params)
		commit('UPDATE_STATE', { data: false, key: 'aggregateListLoading' })
		if (res.code === 200) {
			commit('UPDATE_STATE', { data: res.data || { list: [], total: 0 }, key: 'aggregateList' })
		}
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
