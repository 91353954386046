import { getModelList } from '@/api/journeyMap/model.js'

const state = {
	modelResultLoading: false,
	modelResult: {
		list: [],
		total: 0,
	}
}

const mutations = {
	UPDATE_STATE: (state, { key = '', data = null }) => {
		state[key] = data;
	},
}

const actions = {
    updateState({ commit }, { key, data = null }) {
        commit('UPDATE_STATE', { key, data })
    },
	// 获取实体列表
	async getModelList({ commit }, { params = {} }) {
		commit('UPDATE_STATE', { key: 'modelResultLoading', data: true, })
		const res = await getModelList(params)
		commit('UPDATE_STATE', { key: 'modelResultLoading', data: false, })
		if (res.code === 200) {
			commit('UPDATE_STATE', { key: 'modelResult', data: res.data || { list: [], total: 0 } })
		}
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
