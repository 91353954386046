// 旅程度量
import request from '@/utils/request'

// 获取度量列表
export function getMeasureList(params) {
    return request({
        url: `/journey/metrics/ex-point/measure/list`,
        method: 'get',
        params
    })
}

// 创建度量
export function createMeasure(data) {
    return request({
        url: `/journey/metrics/ex-point/measure/add`,
        method: 'post',
        data
    })
}

// 更新度量
export function updateMeasure(data) {
    return request({
        url: `/journey/metrics/ex-point/measure/update`,
        method: 'put',
        data
    })
}

// 根据Id 删除度量
export function deleteMeasureById(id) {
    return request({
        url: `/journey/metrics/ex-point/measure/delete?id=${id}`,
        method: 'delete',
    })
}

// 获取体验点指标列表
export function getExpointList(data) {
    return request({
        url: `/journey/metrics/expoint/list`,
        method: 'post',
        data
    })
}

// 创建基础体验点
export function createExpoint(data) {
    return request({
        url: `/journey/metrics/expoint/add`,
        method: 'post',
        data
    })
}
// 创建基础体验点
export function updateExpoint(data) {
    return request({
        url: `/journey/metrics/expoint/update`,
        method: 'put',
        data
    })
}

// 创建基础体验点
export function deleteExpointById(id) {
    return request({
        url: `/journey/metrics/expoint/delete?id=${id}`,
        method: 'delete',
    })
}

// 获取聚合指标列表
export function getAggregateList(data) {
    return request({
        url: `/journey/metrics/aggregate/list`,
        method: 'post',
        data
    })
}

// 获取合并口径
export function getJoinCaliber() {
    return request({
        url: `/journey/metrics/aggregate/join-caliber`,
        method: 'get',
    })
}

// 创建聚合体验点
export function createAggregate(data) {
    return request({
        url: `/journey/metrics/aggregate/add`,
        method: 'post',
        data
    })
}

// 修改聚合指标
export function updateAggregate(data) {
    return request({
        url: `/journey/metrics/aggregate/update`,
        method: 'put',
        data
    })
}

// 获取体验点 按度量分类
export function getExpointByMeasure() {
    return request({
        url: `/journey/metrics/aggregate/list-by-measure`,
        method: 'post',
    })
}
// 根据id删除聚合指标
export function deleteAggregateById(id) {
    return request({
        url: `/journey/metrics/aggregate/delete?id=${id}`,
        method: 'delete',
    })
}

// 批量删除聚合指标
export function deleteAggregateBatch(idSet) {
    return request({
        url: `/journey/metrics/aggregate/batch?idSet=${idSet}`,
        method: 'delete',
    })
}

