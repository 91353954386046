import request from '@/api/npsAnalysis/request'
import { getStartDayOfMonth, getEndDayOfMonth, getCycle, getYearOrYear, configField } from '@/views/NPSAnalysis/utils/index'
import { getDictData, getShopList, getProjectList, getNpsProjectList, getCsiProjectList } from '@/api/npsAnalysis/index.js' 

const n = 1000 * 60 * 60 * 24 -1
const state = {
  chartData: {
    // 评分概览
    chart1: {},
    chart2: {},
    chart3: {},
    chart4: {},

    // 排名统计
    chart5: {},
    chart6: {},
    chart7: {},
    chart13: {},

    // 原因分析
    chart8: {},
    chart9: {},
    chart10: {},
    chart11: {},
    chart12: {},
  },
  loading: {
    // 评分概览
    chart1: false,
    chart2: false,
    chart3: false,
    chart4: false,
    // 排名统计
    chart5: false,
    chart6: false,
    chart7: false,
    chart13: false,

    // 原因分析
    chart8: false,
    chart9: false,
    chart10: false,
    chart11: false,
    chart12: false,
  },
  resultSuccess: {
    // 评分概览
    chart1: false,
    chart2: false,
    chart3: false,
    chart4: false,
    // 排名统计
    chart5: false,
    chart6: false,
    chart7: false,
    chart13: false,

    // 原因分析
    chart8: false,
    chart9: false,
    chart10: false,
    chart11: false,
    chart12: false,
  },
  shops: [],
  defaultRegion: [],
  pageType: 'NPS',
  projectNames: {}
}

const mutations = {
  UPDATE_CHART: (state, payload) => {
    const { chardId, data } = payload
    state.chartData[chardId] = data
  },
  UPDATE_LOADING: (state, { chardId, bool, success }) => {
    state.loading[chardId] = bool
    if (typeof success === 'boolean') state.resultSuccess[chardId] = success
  },
  UPDATE_FILTER: (state, { value, key }) => {
    state.filter[key].value = typeof value === 'string' ? [value] : value;
  },
  UPDATE_PAYLOAD: (state) => {
    const filter = state.filter;
    const result = [filter.time]

    for(let key in filter) {
      if (key !== 'time' && filter[key].value) {
        const o = { ...filter[key] }
        result.push(o)
      } else if (key === 'region') {
        const o = { ...filter[key], value: state.defaultRegion }
        result.push(o)
      }
    }

    state.payload.filter = result;
  },
  INIT_PAYLOAD: (state) => {
    for (let k in state.filter) {
      if (k === 'time') {
        state.filter[k].value = [getStartDayOfMonth(0).getTime(), getEndDayOfMonth(0).getTime() + n];
      } else {
        state.filter[k].value = null;
      }
    }
  },
  UPDATE_SHOPS: (state, payload) => {
    state.shops = payload
  },
  UPDATE_DEFAULT_REGION: (state, payload) => {
    state.defaultRegion = payload;
  },
  UPDATE_EXPERIENCE: (state, { data, type }) => {
    if (type === 'bar') {
      state.experience.bar = data
    } else if (type === 'matrix'){
      state.experience.matrix = data
    }
  },
  ININT_STATE: (state, type) => {
    const target = configField[type]
    for (let k in target) {
      state[k] = target[k]
    }
    state.pageType = type;
  },
  UPDATR_PROJECTNAMES: (state, payload) => {
    state.projectNames = payload;
  }
}

const actions = {
  initState({ commit }, type) {
    commit('ININT_STATE', type)
  },
  async getChart({ state, dispatch }, payload) {
    const { query = {}, pageId, chardId, type } = payload
    const params = { ...state.payload, ...query }

    if (pageId === 'analysisId' ) {
      const analysisFieldId = state.analysisFieldId
      const wordFieldId = state.wordFieldId
      const experience = state.experience;
      const arr = []
      const filter = state.filter
      
      for (let k in filter) {
        let o = { ...filter[k] }

        if ((chardId === 'chart8' || chardId === 'chart9' || chardId === 'chart10') && k === 'region' && type === 'level1') {
          o.fieldId =  analysisFieldId.region2
        } else if (chardId !== 'chart11' && chardId !== 'chart12') {
          o.fieldId = analysisFieldId[k]
        } else {
          o.fieldId = wordFieldId[k]
        }
        if (k === 'region' && !o.value) {
          o.value = state.defaultRegion
        }
        if (o.value) {
          arr.push(o)
        }
      }

      if ((chardId === 'chart8' || chardId === 'chart9') && experience.bar && experience.bar.length) {
        arr.push({
          operator: experience.operator,
          isTree: experience.isTree,
          fieldId: experience.fieldId,
          value: experience.bar,
        })
      }

      if (chardId === 'chart10' && experience.matrix && experience.matrix.length) {
        arr.push({
          operator: experience.operator,
          isTree: experience.isTree,
          fieldId: experience.fieldId,
          value: experience.matrix,
        })
      }

      params.filter = arr;
    }
    
    dispatch('getData', { ...payload, data: params })
  },

  async getData({ state, dispatch, commit }, payload) {
    const { pageId, data: dataSource, chardId, filter, type } = payload
    const { chartID, linkPWDToken } = state  
    let id = chartID[pageId][chardId]
    
    if (type) id = chartID[pageId][chardId][type]
    commit('UPDATE_LOADING', { chardId, bool: true })
    try {
      const res = await request({
        url: `/de-proxy-api/api/link/viewDetail/${id}/${state[pageId]}`,
        method: 'post',
        data: dataSource,
        headers: {
          'LINK-PWD-TOKEN': linkPWDToken[pageId]
        }
      })

      if (res.success) {
        var data = res.data;
        const chartIdList = ['chart2', 'chart3']

        if (data.data && 
            (data.data.data && data.data.data.length || 
            ( chartIdList.includes(chardId) && 
            data.data.tableRow && 
            data.data.tableRow.length)
          )
        ) {
          commit('UPDATE_LOADING', { chardId, bool: false, success: true })
          if(filter){
            var newData = filter(data);
            dispatch('initChartData', { chardId, data: newData })
          } else {
            dispatch('initChartData', { chardId, data })
          }
        } else {
          if (filter) filter(data);
          commit('UPDATE_LOADING', { chardId, bool: false, success: false })
          dispatch('initChartData', { chardId, data: null })
        }
        
      } else {
        commit('UPDATE_LOADING', { chardId, bool: false, success: false })
        dispatch('initChartData', { chardId, data: null })
      }
    } catch {
      dispatch('initChartData', { chardId, data: null })
      commit('UPDATE_LOADING', { chardId, bool: false, success: false })
    }
  },

  initChartData ({ commit }, payload) {
    const { data, chardId } = payload

    if (data) {
      if (data.render?.includes('antv')) {
        data.chartType = data?.type?.includes('table') ? 'antv_s2' : 'antv_g2'
      }
  
      const customStyleObj = JSON.parse(data.customStyle)
      const customAttrObj = JSON.parse(data.customAttr)
      data.customStyle = JSON.stringify(customStyleObj)
      data.customAttr = JSON.stringify(customAttrObj)
  
      data.drillFields = data.drillFields ? JSON.parse(data.drillFields) : []
    }
    
    commit('UPDATE_CHART', { chardId, data })
  },
  // 过滤
  updateFilter({ commit }, payload) {
    commit('UPDATE_FILTER', payload)
    commit('UPDATE_PAYLOAD')
  },

  // 获取区域信息
  async getRegion({ state, dispatch, commit }, { getTree, callback }) {
    const shopRes = await getShopList()
    if (shopRes.code === 200) {
      const result = {}
      const data = shopRes.data
      data.forEach((item) => {
        result[item.id] = item
      })

      const treeData = getTree(data, "id", "parentId", "children", '1111')

      const lastShop = []
      const getLastShop = (item) => {
        if (item.children) {
          item.children.forEach(o => getLastShop(o))
        } else {
          lastShop.push(item)
        }
      }

      for (let i = 0; i < treeData.length; i++) {
        getLastShop(treeData[i])
      }
      
      const getAllName = (item, o) => {
        if (item?.parentId != 0) {
          o.unshift(item.name)
          if (item.parentId && result[item.parentId]) getAllName(result[item.parentId], o)
        }
      }
      
      const region = []
      for(let i = 0; i < lastShop.length; i++) {
        const arr = []
        getAllName(lastShop[i], arr)
        region.push(arr.join(','))
      }

      commit('UPDATE_DEFAULT_REGION', region)
      commit('UPDATE_PAYLOAD')
      // dispatch('updateFilter', { value: region, key: 'region' })
      commit('UPDATE_SHOPS', treeData)

      callback()
    }
  },
  // 获取问卷信息
  async getProject({ state, dispatch, commit}, callback) {
    const { filter, pageType } = state
    const fn = pageType === 'NPS' ? getNpsProjectList : getCsiProjectList
    const res = await fn()
    if (res.code === 200) {
      const data = res.data || []
      let result = ''
      const list = data.filter(o => o.status !== 0)

      if (list && list.length) {
        for(let o of list) {
          if (o.status === 1) {
            result = o.id
            break
          }
        }
      }
      const projectNames = {}
      for (let o of data) {
        projectNames[o.id] = o.name
      }

      if(result && !(filter.project.value && filter.project.value.length)) {
        if (process.env.VUE_APP_ENVIRONMENT !== 'demo') commit('UPDATE_FILTER', { key: 'project', value: result })
        commit('UPDATE_PAYLOAD')
        commit('UPDATR_PROJECTNAMES', projectNames)
      }

      callback(list)
    } else {
      callback([])
    }
    
  },
  // 获取城市排名
  async getCityRanking ({ state }, callback) {
    const { payload: dataSource, linkPWDToken, chartID, pageType } = state
    let linkId = chartID.statisticsId.chart13;
    const result = []
    const cityMap = {}; // 城市名称map - 存区域名称
    const linkDataMap = {}; // 关联数据 - key： 区域名称 / val：城市名称 按顺序；可得城市在区域中的排名
    const linkRes = await request({
      url: `/de-proxy-api/api/link/viewDetail/${linkId}/${state.statisticsId}`,
      method: 'post',
      data: dataSource,
      headers: {
        'LINK-PWD-TOKEN': linkPWDToken.statisticsId
      }
    })

    if (linkRes.success) {
      const data = linkRes.data?.data?.data || []
      data.forEach((item) => {
        const { value, dimensionList = [], category } = item
        if (category) {
          const regionName = dimensionList[0].value
          const cityName = dimensionList[1].value
          cityMap[cityName] = regionName
          if (linkDataMap[regionName]) {
            linkDataMap[regionName].push(cityName)
          } else {
            linkDataMap[regionName] = [cityName]
          }
        }
      })
    }

    let cityId = chartID.statisticsId.chart7.city;
    
    const cityRes = await request({
      url: `/de-proxy-api/api/link/viewDetail/${cityId}/${state.statisticsId}`,
      method: 'post',
      data: dataSource,
      headers: {
        'LINK-PWD-TOKEN': linkPWDToken.statisticsId
      }
    })

    if (cityRes.success) {
      const data = cityRes.data?.data?.data || []
      const str = pageType === 'CSI' ? '%' : ''
      data.forEach(item => {
        const { name, category, value } = item
        if (category) {
          const len = result.length;
          const o = {
            NPS: Math.round(value *100) + str,
            cityName: name,
            cityRanking: linkDataMap[cityMap[name]].indexOf(name) +1,
          }

          if (len) {
            o.regionRanking = result[len-1].NPS === o.NPS ? result[len-1].regionRanking : result[len-1].regionRanking + 1;
          } else {
            o.regionRanking = 1;
          }

          result.push(o)
        }
      })

      callback(result)
    }
    
  },
  // 获取店铺排名
  async getShopRanking ({ state }, { callback, categoryMap, grantTypeMap }) {
    const { payload: dataSource, linkPWDToken, chartID, pageType } = state
    let linkId = chartID.statisticsId.chart13;
    const result = []
    const cityMap = {};
    const shopMap = {}
    const linkDataMap = {}
    const linkRes = await request({
      url: `/de-proxy-api/api/link/viewDetail/${linkId}/${state.statisticsId}`,
      method: 'post',
      data: dataSource,
      headers: {
        'LINK-PWD-TOKEN': linkPWDToken.statisticsId
      }
    })

    if (linkRes.success) {
      const data = linkRes.data?.data?.data || []
      data.forEach((item) => {
        const { dimensionList = [], category } = item
        if (category) {
          const regionName = dimensionList[0].value
          const cityName = dimensionList[1].value
          const shopName = dimensionList[2].value
          cityMap[shopName] = regionName
          shopMap[shopName] = cityName

          if (linkDataMap[regionName]) {
            linkDataMap[regionName].push(shopName)
          } else {
            linkDataMap[regionName] = [shopName]
          }

          if (linkDataMap[cityName]) {
            linkDataMap[cityName].push(shopName)
          } else {
            linkDataMap[cityName] = [shopName]
          }
        }
      })
    }

    let shopId = chartID.statisticsId.chart7.shop;
    
    const shopRes = await request({
      url: `/de-proxy-api/api/link/viewDetail/${shopId}/${state.statisticsId}`,
      method: 'post',
      data: dataSource,
      headers: {
        'LINK-PWD-TOKEN': linkPWDToken.statisticsId
      }
    })

    if (shopRes.success) {
      const data = shopRes.data?.data?.data || []
      const str = pageType === 'CSI' ? '%' : ''
      data.forEach(item => {
        const { category, value, dimensionList } = item
        if (category) {
          const name = dimensionList[0].value
          const len = result.length
          const o = {
            NPS: Math.round(value * 100) + str,
            shopName: name,
            cityRanking: linkDataMap[cityMap[name]].indexOf(name) +1,
            shopRanking: linkDataMap[shopMap[name]].indexOf(name) +1,
            category: dimensionList[1].value,
            grantType: grantTypeMap[dimensionList[2].value],
          }

          if (len) {
            o.regionRanking = result[len-1].NPS === o.NPS ? result[len-1].regionRanking : result[len-1].regionRanking + 1;
          } else {
            o.regionRanking = 1;
          }

          result.push(o)
        }
      })

      callback(result)
    }
    
  },
  // 获取有效问卷
  async getValidQuestionnaire({ state }, callback) {
    const { linkPWDToken } = state
    const res = await request({
      url: `/de-proxy-api/dataset/field/linkMultFieldValues`,
      method: 'post',
      data: {
        fieldIds: ["3a959ec1-2ae4-4d41-a606-3e56507fce36"]
      },
      headers: {
        'LINK-PWD-TOKEN': linkPWDToken.statisticsId
      }
    })

    if (res.success) {
      callback(res.data)
    }
  },
  // 获取满意度
  async getSatisfactionRate({ state }, callback) {
    const { linkPWDToken, pageType } = state
    const fieldIds = pageType === 'NPS' ? ["0f707411-e352-4564-adfc-b9a4ee6d5a98", 'b6966348-4bb6-4f3f-a181-f7a502dd8bd3'] : 
    ["2f9aa5eb-2453-42ab-9376-8de67ecce885", "c611360a-e6ff-4def-bd16-d85788370717"]
    const res = await request({
      url: `/de-proxy-api/dataset/field/linkMappingFieldValues`,
      method: 'post',
      data: {
        fieldIds
      },
      headers: {
        'LINK-PWD-TOKEN': linkPWDToken.statisticsId
      }
    })

    if (res.success) {
      callback(res.data)
    }
  },
  // 下载表格
  async downloadChart({ state }, { payload, callback, pageId } ) {
    const { linkPWDToken } = state
    const res = await request({
      url: `/de-proxy-api/panel/group/exportDetails`,
      method: 'post',
      data: payload,
      responseType: 'blob',
      headers: {
        'LINK-PWD-TOKEN': linkPWDToken[pageId]
      }
    })
    callback(res)
  },
  // 获取仪表盘数据
  async getNpsData ({ state, commit, dispatch }, { callback, filter: filterFn }) {
    const { linkPWDToken, overviewResourceId, npsData, payload, chartID, filter } = state

    let total = 0; // 样本量
    let pre = '/'; // 年同比
    let chain = '/'; // 环比
    let data = {};
    let result1 = '-';
    let result2 = '-';
    let result3 = '-';
    
    // 修改请求 filter 时间内容
    const updateTime = (arr, val) => {
      return arr.map((item) => {
        if (item.fieldId === filter.time.fieldId) item.value = val
        return item
      })
    }
    
    commit('UPDATE_LOADING', { chardId: 'chart1', bool: true })
    
    // 获取样本量
    try {
      const totalRes = await request({
        url: `/de-proxy-api/api/link/viewDetail/${npsData.yb}/${overviewResourceId}`,
        method: 'post',
        data: payload,
        headers: {
          'LINK-PWD-TOKEN': linkPWDToken.overviewResourceId
        }
      })

      if (totalRes.success && totalRes?.data?.data?.tableRow) {
        total = Object.values(totalRes?.data?.data?.tableRow[0])[0]
      }
    } catch {}
    
    // 获取当期数据
    try {      
      // 获取本期环比值
      const dayRes = await request({
        url: `/de-proxy-api/api/link/viewDetail/${chartID.overviewResourceId.chart1}/${overviewResourceId}`,
        method: 'post',
        data: payload,
        headers: {
          'LINK-PWD-TOKEN': linkPWDToken.overviewResourceId
        }
      })
 
      if (dayRes.success) {
        if (dayRes?.data?.data?.tableRow) result1 = Object.values(dayRes?.data?.data?.tableRow[0])[0]
        data = dayRes.data
        commit('UPDATE_LOADING', { chardId: 'chart1', bool: true, success: true })
      }
      
    } catch (error) {
      commit('UPDATE_LOADING', { chardId: 'chart1', bool: false, success: false })
    }

    if (result1 !== '-') {
      // 获取上期数据 进行环比
      try {
        const preTime = getCycle(filter.time.value); // 环比上一期时间
        const prePayload = JSON.parse(JSON.stringify(payload)); // 环比时间
        prePayload.filter = updateTime(prePayload.filter, preTime); // 修改环比请求时间
        const preDayRes = await request({
          url: `/de-proxy-api/api/link/viewDetail/${chartID.overviewResourceId.chart1}/${overviewResourceId}`,
          method: 'post',
          data: prePayload,
          headers: {
            'LINK-PWD-TOKEN': linkPWDToken.overviewResourceId
          }
        })

        if (preDayRes.success && preDayRes?.data?.data?.tableRow) {
          result2 = Object.values(preDayRes?.data?.data?.tableRow[0])[0]
        }

        chain = typeof result2 === 'number' ? result1 - result2 : '/';
      } catch (error) {
        commit('UPDATE_LOADING', { chardId: 'chart1', bool: false })
      }

      // 获取上期数据 进行同比
      try {
        const yearTime = getYearOrYear(filter.time.value); // 环比上一期时间
        const yearPayload = JSON.parse(JSON.stringify(payload)); // 环比时间
        yearPayload.filter = updateTime(yearPayload.filter, yearTime); // 修改环比请求时间
        const yearRes = await request({
          url: `/de-proxy-api/api/link/viewDetail/${chartID.overviewResourceId.chart1}/${overviewResourceId}`,
          method: 'post',
          data: yearPayload,
          headers: {
            'LINK-PWD-TOKEN': linkPWDToken.overviewResourceId
          }
        })

        if (yearRes.success && yearRes?.data?.data?.tableRow) {
          result3 = Object.values(yearRes?.data?.data?.tableRow[0])[0]
        }

        pre = typeof result3 === 'number'? result1 - result3 : '/';
      } catch (error) {
        commit('UPDATE_LOADING', { chardId: 'chart1', bool: false })
      }
    }

    
    callback({ total, pre, chain })
    commit('UPDATE_LOADING', { chardId: 'chart1', bool: false })
    
    if (total) {
      if (filterFn) {
        const newData = filterFn(data)
        dispatch('initChartData', { chardId: 'chart1', data: newData })
      }
    } else {
      commit('UPDATE_LOADING', { chardId: 'chart1', bool: false, success: false })
    }
  },

  async getRecommendationAndDerogation({ state, commit, dispatch }, { type, callback, filter: filterFn }) {
    const { pageType} = state
    // 获取玫瑰图数据
    Promise.all([new Promise(resolve => {
      dispatch('getChart', { chardId: 'chart2', pageId: 'overviewResourceId', filter: (newData) => {
        resolve(newData)
      }})
    }), new Promise(resolve => {
      dispatch('getChart', { chardId: 'chart8', pageId: 'analysisId', type, filter: (newData) => {
        resolve(newData)
      }})
    }), new Promise(resolve => {
      dispatch('getChart', { chardId: 'chart9', pageId: 'analysisId', type, filter: (newData) => {
        resolve(newData)
      }})
    })]).then((data)=> {
      // 获取总人数
      const countData = data[0] &&  data[0].data; // 获取推荐和贬损的总人数
      let recommend = 0; // 推荐
      let derogatory = 0; // 贬损

      const recommendData = data[1]; // 推荐样本量
      const derogatoryData = data[2]; // 贬损样本量

      if (countData) {
        const x = countData.x;
        const tableRow = countData.tableRow;
        const rText = { NPS: '推荐者', CSI: '满意者' }
        const dText = { NPS: '贬损者', CSI: '不满意者' }
        const rIdx = x.indexOf(rText[pageType])
        const dIdx = x.indexOf(dText[pageType])

        tableRow[rIdx] && Object.values(tableRow[rIdx])[0] ? recommend = tableRow[rIdx]['*'] : null
        tableRow[dIdx] && Object.values(tableRow[dIdx])[0] ? derogatory = tableRow[dIdx]['*'] : null
      }

      // 获取推荐原因占比
      if (recommendData.data?.data) {
        recommendData.data.data = recommendData.data.data.map(o => {
          o.value = Math.round((o.value / recommend) * 100)
          return o
        })
        
        dispatch('initChartData', { chardId: 'chart8', data: recommendData })
      }

      // 修改贬损原因占比
      if (derogatoryData.data?.data) {
        derogatoryData.data.data = derogatoryData.data.data.map(o => {
          o.value = Math.round((o.value / derogatory) * 100)
          return o
        })
        
        dispatch('initChartData', { chardId: 'chart9', data: derogatoryData })
      }
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
