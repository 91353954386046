// 旅程
import request from '@/utils/request'

// 获取旅程列表
export function getJourneyList() {
    return request({
        url: `/journey/list`,
        method: 'get',
    })
}

// 新增旅程
export function createJourney(data) {
    return request({
        url: `/journey/add`,
        method: 'post',
        data
    })
}

// 编辑旅程
export function updateJourney(data) {
    return request({
        url: `/journey/update`,
        method: 'put',
        data
    })
}

// 删除旅程
export function deleteJourneyById(id) {
    return request({
        url: `/journey/delete?id=${id}`,
        method: 'delete',
    })
}

// 根绝id获取旅程详情
export function getJourneyDetail(data) {
    return request({
        url: `/journey/detail`,
        method: 'post',
        data
    })
}

// 获取上传图片配置 
export function getPictureList() {
    return request({
        url: `/journey/picture/config`,
        method: 'get',
    })
}

// 上传图片
export function uploadImage(data) {
    return request({
        url: `/journey/picture/upload`,
        method: 'post',
        data,
        header: {
            'content-type': 'multipart/form-data'
        }
    })
}


// 上传自定义图片

export function uploadCustomImage(data) {
    return request({
        url: `/journey/picture/upload/custom`,
        method: 'post',
        data,
        header: {
            'content-type': 'multipart/form-data'
        }
    })
}

// 保存旅程详情配置
export function updateJourneyConfig(data) {
    return request({
        url: `/journey/config/update`,
        method: 'put',
        data,
    })
}

// 根据id 将旅程设置为模版
export function addJourneyToTemp(journeyId) {
    return request({
        url: `/journey/template/add`,
        method: 'post',
        data: {
            journeyId
        },
    })
}

// 删除旅程模版
export function deleteJourneyTempById(id) {
    return request({
        url: `/journey/template/delete?id=${id}`,
        method: 'delete',
    })
}


// 获取旅程模版列表
export function getJourneyTempList() {
    return request({
        url: `/journey/template/list`,
        method: 'get',
    })
}
// 根据id获取旅程模版
export function getJourneyTempById(data) {
    return request({
        url: `/journey/template/detail`,
        method: 'post',
        data
    })
}
// 根据旅程id 删除模版
export function deleteJourneyTempByJourneyId(id) {
    return request({
        url: `/journey/template/delete-by-journey-id?journey-id=${id}`,
        method: 'delete',
    })
}
// 创建旅程模版
export function createJourneyTemp(data) {
    return request({
        url: `/journey/template/create`,
        method: 'post',
        data
    })
}

// 上传模版图片
export function uploadTempImage(data) {
    return request({
        url: `/journey/template/picture/upload`,
        method: 'post',
        data,
        header: {
            'content-type': 'multipart/form-data'
        }
    })
}


// 保存旅程详情配置
export function updateJourneyTempConfig(data) {
    return request({
        url: `/journey/template/config/update`,
        method: 'put',
        data,
    })
}

// 创建旅程模版
export function updateJourneyTemp(data) {
    return request({
        url: `/journey/template/update`,
        method: 'put',
        data
    })
}

// 使用模版保存为旅程
export function useJourneyTemp(data) {
    return request({
        url: `/journey/template/use`,
        method: 'put',
        data
    })
}

export function getMetricList(id) {
    return request({
        url: `/journey/metric/list?metricsStatisticsId=${id}`,
        method: 'get',
    })
}