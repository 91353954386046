<template>
  <div :class="`${showLogo && 'has-logo' || ''} ${(isGQ && 'gq-slidebar-wrap') || ''} ${$theme}-slidebar-wrap`"
    :style="{ backgroundColor: settings.sideTheme === 'theme-dark' ? $variables.menuBackground : $variables.menuLightBackground }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
      <el-menu :default-active="activeMenu" :collapse="isCollapse"
        :background-color="settings.sideTheme === 'theme-dark' ? $variables.menuBackground : $variables.menuLightBackground"
        :text-color="settings.sideTheme === 'theme-dark' ? $variables.menuColor : $variables.menuLightColor"
        :collapse-transition="false" menu-trigger="click" mode="vertical">
        <sidebar-item v-for="(route, index) in deepSidebarRouters" :key="route.path + index" :item="route"
          :base-path="route.path" />
      </el-menu>
    </el-scrollbar>

    <canvas style="display: none;" id="watermark-img-canvas" ref="watermark"></canvas>
    <canvas style="display: none;" id="watermark-img-gary-canvas" ref="watermarkGary"></canvas>
    <template v-if="isGy">
      <el-button class="help_btn" icon="el-icon-question" @click="toHelp">帮助中心</el-button>
      <User />
    </template> 
    
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import { getCemToken, setWatermarkKey, setWatermarkGaryKey } from '@/utils/auth'
import User from './User.vue'

export default {
  components: { SidebarItem, Logo, User },
  data() {
    return {
      deepSidebarRouters: []
    }
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebarRouters", "sidebar", "nickname"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
    isGQ() {
      return this.$theme === 'gq'
    },
    isGy() {
      return this.$theme === 'gy'
    },
  },
  mounted() {
    this.deepSidebarRouters = this.sidebarRouters.map(item => {
      if (item.path === '/surveyManager') {
        item.children.forEach(items => {
          if (items.path.indexOf('http') !== -1) {
            items.path = `${items.path}${getCemToken()}`
          }
        })
      }
      return item
    })
    this.backImg()
    this.backGaryImg()
  },
  methods: {
    toHelp() {
      window.open('https://wvbrty8gbrg.feishu.cn/docx/ANFgduT4JodloExuMGLcfXXAnQg?from=from_copylink', '_blank')
    },
     // 绘画水印
    backImg() {
      var canvas = document.getElementById("watermark-img-canvas");
      // rotate
      canvas.width = 1280;
      canvas.height = 612;
      var ctx = canvas.getContext("2d");
      let name = this.nickname ? this.nickname : '广汽传祺'
      var image = new Image();
      //这里可以放png图片，也可以放图片的base64
      image.src = require('@/assets/images/watermark1.png')
      let _this = this
      ctx.fillStyle = '#ffffff';
      let numberDatas = []
      for (let j = 0; j < 35; j++) {
        numberDatas.push(name.length * 30 * (j + 1))
      }
      image.onload = function () {
        var imgwidth = 1280;	//背景图宽度
        var imgheight = 612;	//背景图高度
        ctx.drawImage(image, 0, 0, imgwidth, imgheight);	//背景图上图到画布
        ctx.font = `12px 黑体`
        ctx.textBaseline = 'top'
        ctx.fillStyle = '#33333312';
        ctx.textAlign = 'start'
        ctx.rotate(270)
        ctx.translate(-200, -40)
        for (let i = 0; i < 15; i++) {
          for (let k = 0; k < numberDatas.length; k++) {
            if (k === 0) {
              ctx.fillText(name, Number(numberDatas[k] - numberDatas[k] - numberDatas[k]), 10 + i * 60);
            } else {
              ctx.fillText(name, numberDatas[k], 10 + i * 60);
            }
          }
        }
        _this.$nextTick(() => {
          let imgurl = _this.$refs[`watermark`].toDataURL()
          setWatermarkKey(imgurl)
        })
      }
    },
    // 绘画灰色背景水印
    backGaryImg() {
      var canvas = document.getElementById("watermark-img-gary-canvas");
      // rotate
      canvas.width = 1280;
      canvas.height = 612;
      var ctx = canvas.getContext("2d");
      let name = this.nickname ? this.nickname : '广汽传祺'
      var image = new Image();
      //这里可以放png图片，也可以放图片的base64
      image.src = require('@/assets/images/watermark2.png')
      let _this = this
      ctx.fillStyle = '#eeeeee';
      let numberDatas = []
      for (let j = 0; j < 35; j++) {
        numberDatas.push(name.length * 30 * (j + 1))
      }
      image.onload = function () {
        var imgwidth = 1280;	//背景图宽度
        var imgheight = 612;	//背景图高度
        ctx.drawImage(image, 0, 0, imgwidth, imgheight);	//背景图上图到画布
        ctx.font = `12px 黑体`
        ctx.textBaseline = 'top'
        ctx.fillStyle = '#33333312';
        ctx.textAlign = 'start'
        ctx.rotate(270)
        ctx.translate(-200, -40)
        for (let i = 0; i < 15; i++) {
          for (let k = 0; k < numberDatas.length; k++) {
            if (k === 0) {
              ctx.fillText(name, Number(numberDatas[k] - numberDatas[k] - numberDatas[k]), 10 + i * 60);
            } else {
              ctx.fillText(name, numberDatas[k], 10 + i * 60);
            }
          }
        }
        _this.$nextTick(() => {
          let imgurl = _this.$refs[`watermarkGary`].toDataURL()
          setWatermarkGaryKey(imgurl)
        })
      }
    },
  }
};
</script>
