<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h, context) {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const { icon, title } = context.props
    const vnodes = []
    if (icon && icon != '#') {
      if (isSafari) {
        vnodes.push(<svg-icon class="el_menu_svgIcon el_menu_svgIcon_safari" icon-class={icon} />)
      } else {
        vnodes.push(<svg-icon class="el_menu_svgIcon" icon-class={icon} />)
      }
    }

    if (title) {
      if (title.length > 5) {
        vnodes.push(<span slot='title' title={(title)}>{(title)}</span>)
      } else {
        vnodes.push(<span slot='title'>{(title)}</span>)
      }
    }
    return vnodes
  }
}
</script>
<style>
.el_menu_svgIcon {
  width: 16px !important;
  height: 16px !important;
  padding: 0 !important;

}

.el_menu_svgIcon:hover {
  background: transparent !important;
  border-radius: 4px !important;
}
</style>
