<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse, 'gq-sidebar-logo': isGQ}" :style="{ backgroundColor: sideTheme === 'theme-dark' ? $variables.menuBackground : $variables.menuLightBackground }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <!-- <img v-if="logo2" :src="isGQ ? logo4 : logo2" class="sidebar-logo collapse-logo" /> -->
        <!-- <h1 class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? $variables.logoTitleColor : $variables.logoLightTitleColor }">{{ title }}</h1> -->
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <!-- <img v-if="logo" :src="isGQ ? logo3 : logo5" class="sidebar-logo" /> -->
        <h1 class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? $variables.logoTitleColor : $variables.logoLightTitleColor }">{{ title }} </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
import logoImg from '@/assets/logo/logo.png'
import logoImg2 from '@/assets/logo/logo2.png'
import logoImgGQ from '@/assets/logo/logo_gq.png'
import logoImgGQ2 from '@/assets/logo/logo_gq_2.png'
import logoImgGY from '@/assets/logo/logo_gy.png'
import logoDemo from '@/assets/images/demo/yzhj.jpg'
import logoDemo2 from '@/assets/images/demo/yzhj2.png'


export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    sideTheme() {
      return this.$store.state.settings.sideTheme
    },
    isGQ() {
      return this.$theme === 'gq'
    }
  },
  data() {
    return {
      title: '用户体验管理系统 ',
      logo: logoImg2,
      logo2: logoImg,
      logo3: logoDemo,
      logo4: logoDemo2,
      logo5: logoImgGY,
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 56px;
  background: #2b2f3a;
  text-align: center;
  overflow: hidden;
  text-align: left;
  padding-left: 24px;
  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    .sidebar-logo {
      width: 109px;
      // height: 39px;
      vertical-align: middle;
      margin-right: 12px;
      display: none;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
      font-size: 18px;
    }
  }

  &.collapse {
    padding-left: 0 !important;
    .sidebar-logo {
      margin-right: 0px;
      width: 37px;
      height: 37px;
      margin-top: 10px;
      margin-left: 10px;
    }
  }

  &.gq-sidebar-logo {
    padding-left: 20px;
    .sidebar-logo {
      // width: 164px;
      // height: 30px;
    }

    .collapse-logo {
      width: 37px;
      height: 37px;
      margin-top: 0;
    }
  }
}
</style>
