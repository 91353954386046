import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getAccessToken, setHeaderTitle, getDevice, getCemToken } from '@/utils/auth'
import { isRelogin } from '@/utils/request'

NProgress.configure({ showSpinner: false })
// 增加三方登陆 update by 芋艿
const whiteList = ['/socialLogin', '/404', '/login', '/social-login', '/auth-redirect', '/bind', '/register', '/oauthLogin/gitee']

function getFirstMenu(accessRoutes) {
  // debugger
  for (var i = 0; i < accessRoutes.length; i++) {
    var r = accessRoutes[i];
    let ok = window.location.hostname !== 'localhost' ? r.path : ''
    if (r.visible) {
      if (!r.children || r.children.length < 1) {
        // guyuHome = r.path
        return r.path;
      }
      if (getFirstMenu(r.children)) ok = getFirstMenu(r.children);
      if (ok) {
        if (ok.startsWith("http")) {
          return ok;
        }
        return r.path + "/" + ok;
      }
    }
  }
}
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getAccessToken()) {
    setHeaderTitle(to.meta.title)
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path.indexOf('socialLogin%3F') !== -1) {
      if (getCemToken()) {
        next({ path: '/' })
        NProgress.done()
      } else {
        // url转码
        next(decodeURIComponent(to.path))
        NProgress.done()
      }
    } else if (to.path.indexOf('socialLogin?') !== -1) {
      if (getCemToken()) {
        next({ path: '/' })
        NProgress.done()
      } else {
        // url转码
        next(to.path)
        NProgress.done()
      }
    } else if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        isRelogin.show = true
        // 获取字典数据 add by 芋艿
        store.dispatch('dict/loadDictDatas')
        if (process.env.VUE_APP_THEME !== 'gy') store.dispatch('dict/loadTenantDict'); // 获取租户下特殊配置

        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => {
          isRelogin.show = false
          store.dispatch('GenerateRoutes').then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            router.addRoutes(accessRoutes) // 动态添加可访问路由表
            if (to.fullPath == "/index") {
              //whx
              // debugger;
              var guyuHome = getFirstMenu(accessRoutes);
              if (getDevice()) {
                let str = ''
                let strs = ''
                if (from.query) {
                  for (let key in from.query) {
                    str = str + `${key}=${from.query[key]}&`
                  }
                }
                str = str.substring(0, str.length - 1)
                strs = `?${str}`
                next({ path: `/earlyWarning/indicatorWarning${strs}`, replace: true })
                return
              }
              console.log(guyuHome)
              if (guyuHome && guyuHome.startsWith("http")) {
                window.location.href = guyuHome;
              } else if (guyuHome) {
                if (guyuHome != from.fullPath) {
                  next({ path: guyuHome, replace: true }) // hack方法 确保addRoutes已完成
                }
              } else {
                next({ path: "/", replace: true })
              }

            } else {
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            }

          })
        }).catch(err => {
          store.dispatch('LogOut').then(() => {
            Message.error(err)
            next({ path: '/' })
          })
        })
      } else {
        // debugger;
        if (to.fullPath == "/index") {
          // var accessRoutes = store.getters.addRoutes;
          var guyuHome = getFirstMenu(store.getters.addRoutes)
          console.log(getDevice(), '---getDevice()');
          if (getDevice()) {
            let str = ''
            let strs = ''
            if (from.query) {
              for (let key in from.query) {
                str = str + `${key}=${from.query[key]}&`
              }
            }
            str = str.substring(0, str.length - 1)
            strs = `?${str}`
            next({ path: `/earlyWarning/indicatorWarning${strs}`, replace: true })
            return
          }
          if (guyuHome && guyuHome.startsWith("http")) {
            window.location.href = guyuHome;
          } else if (guyuHome) {
            if (guyuHome != from.fullPath) {
              next({ path: guyuHome, replace: true }) // hack方法 确保addRoutes已完成
            } else {
              next()
            }
          } else {
            next()
          }

        } else {
          next()
        }
      }
    }
  } else {
    // 没有token
    if (to.path.indexOf('socialLogin%3F') !== -1) {
      // url转码
      next(decodeURIComponent(to.path))
    } else if (to.path.indexOf('socialLogin?') !== -1) {
      // url转码
      next(to.path)
    } else if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      if (to.fullPath.indexOf('indicatorWarning?') !== -1) {
        let name = to.fullPath.split('indicatorWarning?')[1]
        var red = encodeURIComponent(to.fullPath)
        next(`/login?redirect=${red}&${name}`) // 否则全部重定向到登录页
        NProgress.done()
      } else {
        // debugger;
        var red = encodeURIComponent(to.fullPath)
        next(`/login?redirect=${red}`) // 否则全部重定向到登录页
        NProgress.done()
      }
    }
  }

  // 跳出nps页面 请求筛选还原
  if (from.path.indexOf('/nps') >= 0 && to.path.indexOf('/nps') < 0 ||
    from.path.indexOf('/csi') >= 0 && to.path.indexOf('/csi') < 0) {
    store.commit('nps/INIT_PAYLOAD')
    store.commit('nps/UPDATE_PAYLOAD')
  }
})

router.afterEach(() => {
  NProgress.done()
})
