var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "main " + (_vm.$theme + "-main") }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.titleConf
          ? _c(
              "div",
              { staticClass: "title-wrap flex" },
              [
                _c("div", { staticClass: "flex" }, [
                  _vm.titleConf.goback
                    ? _c(
                        "span",
                        { staticClass: "back-icon", on: { click: _vm.goback } },
                        [_c("i", { staticClass: "el-icon-arrow-left icon" })]
                      )
                    : _vm._e(),
                  _c("h3", { staticClass: "font-header" }, [
                    _vm._v(" " + _vm._s(_vm.titleConf.title) + " "),
                  ]),
                ]),
                _vm.titleConf.btnVisible
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: {
                          icon: _vm.titleConf.btnIcon || "",
                          type: "primary",
                        },
                        on: { click: _vm.handelClick },
                      },
                      [_vm._v(_vm._s(_vm.titleConf.btnText))]
                    )
                  : _vm._e(),
                _vm.titleConf.rightSlot ? _vm._t("rightBtn") : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm.$theme === "gq" && _vm.showHeaderLine
          ? _c("el-divider", { staticStyle: { "margin-top": "20px" } })
          : _vm._e(),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }