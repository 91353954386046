var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "navbar " + (_vm.$theme ? _vm.$theme + "-navbar" : "") },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "hover" },
              on: {
                "visible-change": function (val) {
                  return (_vm.visible = val)
                },
              },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar },
                }),
                _c("span", { staticClass: "user-slogan" }, [_vm._v("欢迎!")]),
                _vm.nickname
                  ? _c("span", { staticClass: "user-nickname" }, [
                      _vm._v(_vm._s(_vm.nickname)),
                    ])
                  : _vm._e(),
                _vm.visible
                  ? _c("i", { staticClass: "el-icon-arrow-up downIcon" })
                  : _c("i", { staticClass: "el-icon-arrow-down downIcon" }),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "el-dropdown-menu__box",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/user/profile" } },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c("svg-icon", {
                            staticClass: "navBar_svg",
                            attrs: { "icon-class": "gerenzhongxin" },
                          }),
                          _vm._v("个人中心"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.toMessage()
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        [
                          _c("svg-icon", {
                            staticClass: "navBar_svg",
                            attrs: { "icon-class": "xitongxiaoxi" },
                          }),
                          _vm._v("系统消息"),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        [
                          _c("svg-icon", {
                            staticClass: "navBar_svg",
                            attrs: { "icon-class": "tuichudenglu" },
                          }),
                          _vm._v("退出登录"),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }