var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "el-form",
        {
          attrs: {
            size: "mini",
            "label-width": "90px",
            model: _vm.model,
            rules: _vm.rules,
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _vm.elementBaseInfo.$type === "bpmn:Process"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "流程标识", prop: "key" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入流标标识",
                          disabled:
                            _vm.model.id !== undefined &&
                            _vm.model.id.length > 0,
                        },
                        on: { change: _vm.handleKeyUpdate },
                        model: {
                          value: _vm.model.key,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "key", $$v)
                          },
                          expression: "model.key",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "流程名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入流程名称", clearable: "" },
                        on: { change: _vm.handleNameUpdate },
                        model: {
                          value: _vm.model.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "name", $$v)
                          },
                          expression: "model.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "ID" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.updateBaseInfo("id")
                          },
                        },
                        model: {
                          value: _vm.elementBaseInfo.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.elementBaseInfo, "id", $$v)
                          },
                          expression: "elementBaseInfo.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.updateBaseInfo("name")
                          },
                        },
                        model: {
                          value: _vm.elementBaseInfo.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.elementBaseInfo, "name", $$v)
                          },
                          expression: "elementBaseInfo.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }