import request from '@/utils/request'

// 获取体验点分类
export function getMeasureList (data) {
    return request({
        url: `/journey/measure/list`,
        method: 'post',
        data
    })
}


// 创建度量
export function createMeasure(data) {
    return request({
        url: `/journey/measure/add`,
        method: 'post',
        data
    })
}

// 更新度量
export function updateMeasure(data) {
    return request({
        url: `/journey/measure/update`,
        method: 'put',
        data
    })
}

// 更新度量
export function deleteMeasureById(params) {
    return request({
        url: `/journey/measure/delete`,
        method: 'delete',
        params
    })
}