import request from '@/utils/request';
import req from './request'
// 获取门店类型
export function getDictData(keycode) {
  return request({
    url: `/nps/shop-tree/deptCategory`,
    method: 'get'
  })
}

// 获取店铺类型
export function getShopList() {
  return request({
    url: `/nps/shop-tree/list-shop-simple`,
    method: 'get'
  })
}

// 获取所有问卷名称
export function getProjectList() {
  return req({
    url: `/api/survey-api/projectList`,
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_API_2,
    noNeedToken: true,
  })
}
// 获取nps看板中可展示问卷
export function getNpsProjectList() {
  return req({
    url: `/api/survey-api/projectNpsList`,
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_API_2,
    noNeedToken: true,
  })
}

// 获取csi看板中可展示问卷
export function getCsiProjectList() {
  return req({
    url: `/api/survey-api/projectCsiList `,
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_API_2,
    noNeedToken: true,
  })
}