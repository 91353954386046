// 旅程体验点
import request from '@/utils/request'

// 获取基础体验点列表
export function getBasicList(data) {
    return request({
        url: `/journey/expoint/basic/list`,
        method: 'post',
        data
    })
}
// 创建基础体验点
export function createBasic(data) {
    return request({
        url: `/journey/expoint/basic/add`,
        method: 'post',
        data
    })
}

// 修改基础体验点
export function updateBasic(data) {
    return request({
        url: `/journey/expoint/basic/update`,
        method: 'put',
        data
    })
}
// 删除基础体验点
export function deteleBasic(id) {
    return request({
        url: `/journey/expoint/basic/delete?id=${id}`,
        method: 'delete',
    })
}


// 删除基础体验点
export function deteleBasicBatch(idSet) {
    return request({
        url: `/journey/expoint/basic/batch?idSet=${idSet}`,
        method: 'delete',
    })
}
// 移动到组
export function moveExpoint(data) {
    return request({
        url: `/journey/expoint/group/move`,
        method: 'put',
        data
    })
}


// 复制到组
export function copyExpoint(data) {
    return request({
        url: `/journey/expoint/group/copy`,
        method: 'put',
        data
    })
}

// 移除分组
export function removeExpoint(data) {
    return request({
        url: `/journey/expoint/group/remove`,
        method: 'put',
        data
    })
}


// 获取分组列表
export function getGroupList(params) {
    return request({
        url: `/journey/expoint/group/list`,
        method: 'get',
        params
    })
}

// 创建体验点分组
export function createGroup(data) {
    return request({
        url: `/journey/expoint/group/add`,
        method: 'post',
        data
    })
}


// 编辑分组
export function updateGroup(data) {
    return request({
        url: `/journey/expoint/group/update`,
        method: 'put',
        data
    })
}

export function deteleGroup(id) {
    return request({
        url: `/journey/expoint/group/delete?id=${id}`,
        method: 'delete',
    })
}

// 批量上传体验点
export function createBasicBatch(data) {
    return request({
        url: `/journey/expoint/basic/add/batch`,
        method: 'post',
        headers: {
            'content-type': 'multipart/form-data'
        },
        data
    })
}


// 下载模版
export function downloadTempUrl() {
    return request({
        url: `/journey/expoint/basic/template/download`,
        method: 'get',
        // responseType:'blob',
    })
}

export function downloadTemp(url) {
    return request({
        url,
        method: 'get',
        responseType:'blob',
    })
}
// 基础体验点批量关联实体
export function updateModel(data) {
    return request({
        url: `/journey/expoint/basic/update-model`,
        method: 'put',
        data
    })
}
