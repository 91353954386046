import request from '@/utils/request'

// 创建维度分类
export function createCarBrand(data) {
  return request({
    url: '/cem/car-brand/create',
    method: 'post',
    data: data
  })
}

// 更新维度分类
export function updateCarBrand(data) {
  return request({
    url: '/cem/car-brand/update',
    method: 'put',
    data: data
  })
}

// 删除维度分类
export function deleteCarBrand(id) {
  return request({
    url: '/cem/car-brand/delete?id=' + id,
    method: 'delete'
  })
}

// 获得维度分类
export function getCarBrand(id) {
  return request({
    url: '/cem/car-brand/get?id=' + id,
    method: 'get'
  })
}

// 获得维度分类分页
export function getCarBrandPage(query) {
  return request({
    url: '/cem/car-brand/page',
    method: 'get',
    params: query
  })
}

// 导出维度分类 Excel
export function exportCarBrandExcel(query) {
  return request({
    url: '/cem/car-brand/export-excel',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

// 获得维度分类分页
export function getCarBrandList(query) {
  return request({
    url: '/cem/car-brand/list',
    method: 'get',
    params: query
  })
}