var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      staticClass: "gy-avatar-container right-menu-item hover-effect",
      attrs: { trigger: "hover", placement: "top" },
      on: {
        "visible-change": function (val) {
          return (_vm.visible = val)
        },
      },
    },
    [
      _c("div", { staticClass: "avatar-wrapper" }, [
        _c("img", { staticClass: "user-avatar", attrs: { src: _vm.avatar } }),
        _vm.nickname
          ? _c("span", { staticClass: "user-nickname" }, [
              _vm._v(_vm._s(_vm.nickname)),
            ])
          : _vm._e(),
        _vm.visible
          ? _c("i", { staticClass: "el-icon-arrow-up downIcon" })
          : _c("i", { staticClass: "el-icon-arrow-down downIcon" }),
      ]),
      _c(
        "el-dropdown-menu",
        {
          staticClass: "gy-avatar-menu",
          attrs: { slot: "dropdown" },
          slot: "dropdown",
        },
        [
          _c(
            "router-link",
            { attrs: { to: "/user/profile" } },
            [_c("el-dropdown-item", [_vm._v("个人中心")])],
            1
          ),
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function ($event) {
                  return _vm.logout($event)
                },
              },
            },
            [_c("span", [_vm._v("退出登录")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }