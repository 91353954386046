import Vue from 'vue'

import Element from 'element-ui'
import './assets/styles/element-variables.scss'

if (process.env.VUE_APP_THEME === 'gq') {
  require('./assets/styles/gq/element-ui-gq.scss')
} else if (process.env.VUE_APP_THEME === 'gy') {
  require('./assets/styles/gy/element-ui-gy.scss')
} else {
  require('./assets/styles/element-ui.scss')
}
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import variables from '@/assets/styles/variables.scss';
import variablesGq from '@/assets/styles/variables-gq.scss';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import App from './App'
import store from './store'
import router from './router'
import directive from './directive' // directive
import plugins from './plugins' // plugins
import moment from 'moment'
import initDirectives from '@/utils/directives';

import './assets/icons' // icon

/** iconfont
 * Symbol 引用
 */
import './assets/iconfont/iconfont'

import './permission' // permission control
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/infra/config";
import { parseTime, resetForm, handleTree, handleTreeGuyu, handleTreeWhx } from "@/utils/ruoyi";
import Pagination from "@/components/Pagination";
// 自定义表格工具扩展
import RightToolbar from "@/components/RightToolbar"
import JsonViewer from 'vue-json-viewer'
import ContainerCom from '@/components/Container'

// 代码高亮插件
// import hljs from 'highlight.js'
// import 'highlight.js/styles/github-gist.css'
import { DICT_TYPE, getDictDataLabel, getDictDatas, getDictDatas2 } from "@/utils/dict";

// 多个盒子连线插件
import jsPlumb from 'jsplumb'
Vue.prototype.$jsPlumb = jsPlumb.jsPlumb

// 数字从指定数到指定数变化小伙插件
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.getDictDatas = getDictDatas
Vue.prototype.getDictDatas2 = getDictDatas2
Vue.prototype.getDictDataLabel = getDictDataLabel
Vue.prototype.DICT_TYPE = DICT_TYPE
Vue.prototype.handleTree = handleTree
Vue.prototype.handleTreeGuyu = handleTreeGuyu
Vue.prototype.handleTreeWhx = handleTreeWhx

// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('DocAlert', DocAlert)
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('JsonViewer', JsonViewer)
Vue.component('ContainerCom', ContainerCom)

// 字典标签组件
import DictTag from '@/components/DictTag'
import DocAlert from '@/components/DocAlert'
// 头部标签插件
import VueMeta from 'vue-meta'

Vue.use(directive)
Vue.use(plugins)
Vue.use(VueMeta)
// Vue.use(hljs.vuePlugin);

//global registration
// import VueStepWizard from 'vue-step-wizard'
// import 'vue-step-wizard/dist/vue-step-wizard.css'
// Vue.use(VueStepWizard);

// bpmnProcessDesigner 需要引入
import MyPD from "@/components/bpmnProcessDesigner/package/index.js";
Vue.use(MyPD);
import "@/components/bpmnProcessDesigner/package/theme/index.scss";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";

// Form Generator 组件需要使用到 tinymce
import Tinymce from '@/components/tinymce/index.vue'
Vue.component('tinymce', Tinymce)
import '@/icons'
import request from "@/utils/request" // 实现 form generator 使用自己定义的 axios request 对象
Vue.prototype.$axios = request
import '@/styles/index.scss'

// 默认点击背景不关闭弹窗
import ElementUI from 'element-ui'
ElementUI.Dialog.props.closeOnClickModal.default = false

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
const locale = {
  el: {
    ...zhLocale.el,
    pagination: {
      ...zhLocale.el.pagination,
      total: `共{total}条数据`,
      goto: '跳至',
    }
  }
}
Vue.use(Element, {
  size: localStorage.getItem("size") || "medium",
  locale,
});

Vue.config.productionTip = false
if (process.env.VUE_APP_THEME == 'gq') { // 广汽
  Vue.prototype.$variables = variablesGq
} else if (process.env.VUE_APP_THEME == 'gy') { // cem-V2
  const gy = require('./assets/styles/gy/variables-gy.scss')
  Vue.prototype.$variables = gy;
} else {
  Vue.prototype.$variables = variables;
}
Vue.prototype.$theme = process.env.VUE_APP_THEME

Vue.filter('formatDate', (val) => {
  if (!val) return ''

  return moment(val).format('YYYY-MM-DD')
})


Vue.filter('formatTime', (val) => {
  if (!val) return ''

  return moment(val).format('YYYY-MM-DD HH:mm:ss')
})

initDirectives(Vue); // Vue全局自定义指令 - 标题是否展示tooltip
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
