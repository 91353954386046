import request from '@/api/npsAnalysis/request';
import { configFieId } from '@/views/socialMedia/utils/index.js';
const CONFIG_CHART_DATA = JSON.stringify({ data: null, loading: false, result: false })
const dashboardId = '829aecf8-d947-455a-84d6-2fdabb1c7af9';
const dashboardData = JSON.stringify({ filter:[], linkageFilters:[], drill:[], resultCount:1000, resultMode:"all", queryFrom:"panel", cache:true });
const dashboardTotalId = '8adeb04e-6a49-4fe9-bb24-365aa70286ae'
const state = {
  ...configFieId,
  timeFieId: dashboardId,
  chartData: {
    chart1: JSON.parse(CONFIG_CHART_DATA),
    chart2: JSON.parse(CONFIG_CHART_DATA),
    chart3: JSON.parse(CONFIG_CHART_DATA),
    chart4: JSON.parse(CONFIG_CHART_DATA),
    chart5: JSON.parse(CONFIG_CHART_DATA),
    chart6: JSON.parse(CONFIG_CHART_DATA),
    chart7: JSON.parse(CONFIG_CHART_DATA),
    chart8: JSON.parse(CONFIG_CHART_DATA),
    chart9: JSON.parse(CONFIG_CHART_DATA),
    chart10: JSON.parse(CONFIG_CHART_DATA),
    chart11: JSON.parse(CONFIG_CHART_DATA),
    chart12: JSON.parse(CONFIG_CHART_DATA),
    chart13: JSON.parse(CONFIG_CHART_DATA),
    chart14: JSON.parse(CONFIG_CHART_DATA),
    chart15: JSON.parse(CONFIG_CHART_DATA),
    chart16: JSON.parse(CONFIG_CHART_DATA),
    chart17: JSON.parse(CONFIG_CHART_DATA),
    rankingDetailList: JSON.parse(CONFIG_CHART_DATA),
  },
  chartCar: {
    awt: JSON.parse(CONFIG_CHART_DATA),
    xiaopeng: JSON.parse(CONFIG_CHART_DATA),
    aodi: JSON.parse(CONFIG_CHART_DATA),
    weilai: JSON.parse(CONFIG_CHART_DATA),
    tesila: JSON.parse(CONFIG_CHART_DATA), 
    lixiang: JSON.parse(CONFIG_CHART_DATA),
    baoma: JSON.parse(CONFIG_CHART_DATA),
    zhiji: JSON.parse(CONFIG_CHART_DATA),
    benchi: JSON.parse(CONFIG_CHART_DATA),
    aito: JSON.parse(CONFIG_CHART_DATA),
    jihu: JSON.parse(CONFIG_CHART_DATA), 
    jike: JSON.parse(CONFIG_CHART_DATA),
  },
  timeList: [],
}

const mutations = {
  UPDATE_STATE: (state, { key = '', data = null }) => {
    state[key] = data;
  },
  UPDATA_LOADING_OR_RESULT: (state, { chartId = '', bool = false, key = 'loading', chartType = '' }) => {
    if (chartType) {
      state.chartCar[chartType][key] = bool
      if (key === 'result') state.chartCar[chartType].loading = false;
    } else {
      state.chartData[chartId][key] = bool
      if (key === 'result') state.chartData[chartId].loading = false;
    }
  },
  UPDATE_CHART: (state, { chartId, data = null, chartType }) => {
    if (chartId === 'chartCar') {
      state.chartCar[chartType].data = data;
    } else {
      state.chartData[chartId].data = data;
    }
  },
  // 保存获取的筛选数据
  UPDATE_FILTER_DATA: (state, { page, data = null, key = '' }) => {
    state[page].filterData[key] = data;
  },
  INIT_CAR_DATA: (state) => {
    const { chartCar } = state.competitor
    for (let k in chartCar) {
      state.chartCar[k] = JSON.parse(CONFIG_CHART_DATA);
    }
  },
  UPDATE_FILTER: (state, { page, data = null, key = '' }) => {
    state[page].filter[key].value = data;
  },
  UPDATE_PAYLOAD: (state, { page }) => {
    const result = []
    const filter = state[page].filter;
    for (let k in filter) {
      if (filter[k].value && filter[k].value.length) {
        result.push(filter[k])
      }
    }

    state[page].payload.filter = result;
  }
}

const actions = {
  async getChart({ state, dispatch }, payload) {
    const { page, data = null } = payload

    dispatch('getData', { ...payload, data: data || state[page].payload })
  },
  async getData({ state, dispatch, commit }, payload) {
    const { page, chartId, data: dataSource, filter, type = null, callback, chartType } = payload
    const { linkPWDToken, templateId }= state[page];
    let id =  state[page][chartId]
    if (type) id = id[type]

    const loadingObj = { chartId }
    if (chartId === 'chartCar') loadingObj.chartType = chartType

    commit('UPDATA_LOADING_OR_RESULT', { ...loadingObj, bool: true })
    
    try{ 
      const res = await request({
        url: `/de-proxy-api/api/link/viewDetail/${id}/${templateId}`,
        method: 'post',
        data: dataSource,
        headers: {
          'LINK-PWD-TOKEN': linkPWDToken
        }
      })

      if (res.success) {
        const data = res.data
        const chartIdList = [
          'chart2', 'chart3', 'chart4', 'chart5', 'chart6', 'chart7',
          'chart8', 'chart9', 'chart12', 'chart13', 'chart14', 'chartCar', 'rankingDetailList']
        if (data.data && 
          (data.data.data && data.data.data.length || (
          chartIdList.includes(chartId) && 
          data.data.tableRow && 
          data.data.tableRow.length))
        ) {
          commit('UPDATA_LOADING_OR_RESULT', { ...loadingObj, bool: true, key: 'result' })
          // commit('UPDATA_LOADING_OR_RESULT', { chartId, bool: true, key: 'result' })
          if(filter){
            var newData = filter(data);
            dispatch('initChartData', { ...loadingObj, data: newData })
          } else {
            dispatch('initChartData', { ...loadingObj, data })
          }

          if (callback) callback(data)
        } else {
          commit('UPDATA_LOADING_OR_RESULT', { ...loadingObj, bool: false, key: 'result' })
          // commit('UPDATA_LOADING_OR_RESULT', { chartId, bool: false, key: 'result' })
          dispatch('initChartData', { ...loadingObj, data: null })
        }
      } else {
        commit('UPDATA_LOADING_OR_RESULT', { ...loadingObj, bool: false, key: 'result' })
        dispatch('initChartData', { ...loadingObj, data: null })
      }
    } catch {
      commit('UPDATA_LOADING_OR_RESULT', { ...loadingObj, bool: false, key: 'result' })
      dispatch('initChartData', { ...loadingObj, data: null })
    }
    
  },
  initChartData({ commit }, { chartId, data, chartType }) {
    if (data) {
      if (data.render?.includes('antv')) {
        data.chartType = data?.type?.includes('table') ? 'antv_s2' : 'antv_g2'
      }
  
      const customStyleObj = JSON.parse(data.customStyle)
      const customAttrObj = JSON.parse(data.customAttr)
      data.customStyle = JSON.stringify(customStyleObj)
      data.customAttr = JSON.stringify(customAttrObj)
  
      data.drillFields = data.drillFields ? JSON.parse(data.drillFields) : []
    }

    if (chartId === 'chart11' && data) {
      const fields = data?.data?.fields || []
      const tableRow = data?.data?.tableRow || []
      const obj = {}
      fields.forEach(o => {
        obj[o.dataeaseName] = o.name
      })

      const result = []
      tableRow.forEach(o => {
        const v = {}
        for (let k in o) {
          v[obj[k]] = o[k]
        }

        result.push(v)
      })
      data.data.data = result
    }
    
    commit('UPDATE_CHART', { chartId, data, chartType })
  },
  async getLinkMultFieldValues({}, { fieldIds, callback, page, path = '', sort = {}}) {
    const { linkPWDToken } = state[page];
    let url = `/de-proxy-api/dataset/field/linkMultFieldValues`
    if (path) url = `/de-proxy-api/dataset/field/${path}`
    const res = await request({
      url,
      method: 'post',
      data: {
        fieldIds,
        sort
      },
      headers: {
        'LINK-PWD-TOKEN': linkPWDToken
      }
    })

    if (res.success) {
      callback(res.data)
    }
  },

  async getDashboard ({ state, commit, dispatch }, { type, filter, callback }) {
    const { linkPWDToken, chart1, templateId } = state.self;
    const timeList = state.timeList; // 获取本次仪表盘时间

    commit('UPDATA_LOADING_OR_RESULT', { chartId: 'chart1', bool: true })

    let score = 0; // 分数
    let preScore = '/'; // 上期分数 - 环比
    let preYearScore = '/'; // 去年同期分数 - 同比
    let total = 0; // 总样本量
    let current = null; // 当期数据
    if (timeList && timeList.length) {
      const monthDate = timeList[0]
      const getData = async (time, tempId = '') => {
        try {
          const id = tempId || chart1[type]
          const params = JSON.parse(dashboardData)
          params.filter = [
            {
              fieldId: dashboardId,
              operator: "eq",
              value: [time],
            }
          ]
          const res = await request({
            url: `/de-proxy-api/api/link/viewDetail/${id}/${templateId}`,
            method: 'post',
            data: params,
            headers: {
              'LINK-PWD-TOKEN': linkPWDToken
            }
          })

          if (res.success) {
            return res.data
          } else {
            return null
          }
        } catch {}
      }
      // 获取本次数据
      current = await getData(monthDate);
      if (current) score = current.data?.series[0]?.data ? (current.data?.series[0]?.data[0] || 0) : 0;

      // 获取环比 - 上期数据
      if(timeList.length > 1) {
        const preMonthDate = timeList[1]
        const preData = await getData(preMonthDate);
        if (preData) preScore = preData.data?.series[0]?.data && preData.data?.series[0]?.data[0] ? preData.data?.series[0]?.data[0] : '/';
      }

      const preYearDate = (monthDate.slice(0, 4) - 1) + monthDate.slice(4, 6)
      // 获取同比 - 去年同期数据
      if (timeList.includes(preYearDate)) {
        const preYearData = await getData(preYearDate);
        if (preYearData) preYearScore = preYearData.data?.series[0]?.data && preYearData.data?.series[0]?.data[0] ? preYearData.data?.series[0]?.data[0] : '/';
      }

      const totalData = await getData(monthDate, dashboardTotalId)
      if (totalData) total = totalData.data?.series[0]?.data && totalData.data?.series[0]?.data[0] ? totalData.data?.series[0]?.data[0] : 0
    }
    const o = {
      score, chain: preScore, pre: preYearScore, total,
    }
    if (preScore !== '/') o.chain = Math.round((score - preScore) * 100) / 100
    if (preYearScore !== '/') o.pre = Math.round((score - preYearScore) * 100) / 100
    callback(o)

    if (type === 'dzdp') {
      if (total && current) {
        if (filter) {
          const newData = filter(current)
          dispatch('initChartData', { chartId: 'chart1', data: newData })
          commit('UPDATA_LOADING_OR_RESULT', { chartId: 'chart1', bool: true, key: 'result' })
        }
      } else {
        dispatch('initChartData', { chartId: 'chart1', data: null })
        commit('UPDATA_LOADING_OR_RESULT', { chartId: 'chart1', bool: false, key: 'result' })
      }
    } else {
      commit('UPDATA_LOADING_OR_RESULT', { chartId: 'chart1', bool: true, key: 'result' })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
