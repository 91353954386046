<template>
  <div id="app" :style="{ 'min-width': flag ? 'auto' : '1200px' }">
    <router-view />
  </div>
</template>

<script>
import { setDevice, removeDevice } from './utils/auth'
export default {
  name: 'App',
  data() {
    return {
      flag: ''
    }
  },
  metaInfo() {
    return {
      title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
      titleTemplate: title => {
        return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
      }
    }
  },
  mounted() {
    // 将设备信息存入
    if (this._isMobile()?.length) {
      setDevice(this._isMobile()[0])
    } else {
      removeDevice()
    }
  },
  methods: {
    // 判断什么设备进入系统
    _isMobile() {
      this.flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return this.flag ? this.flag : ''
    }
  }
}
</script>
