var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_vm.showLogo && "has-logo") || "") +
        " " +
        ((_vm.isGQ && "gq-slidebar-wrap") || "") +
        " " +
        _vm.$theme +
        "-slidebar-wrap",
      style: {
        backgroundColor:
          _vm.settings.sideTheme === "theme-dark"
            ? _vm.$variables.menuBackground
            : _vm.$variables.menuLightBackground,
      },
    },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c(
        "el-scrollbar",
        {
          class: _vm.settings.sideTheme,
          attrs: { "wrap-class": "scrollbar-wrapper" },
        },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color":
                  _vm.settings.sideTheme === "theme-dark"
                    ? _vm.$variables.menuBackground
                    : _vm.$variables.menuLightBackground,
                "text-color":
                  _vm.settings.sideTheme === "theme-dark"
                    ? _vm.$variables.menuColor
                    : _vm.$variables.menuLightColor,
                "collapse-transition": false,
                "menu-trigger": "click",
                mode: "vertical",
              },
            },
            _vm._l(_vm.deepSidebarRouters, function (route, index) {
              return _c("sidebar-item", {
                key: route.path + index,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("canvas", {
        ref: "watermark",
        staticStyle: { display: "none" },
        attrs: { id: "watermark-img-canvas" },
      }),
      _c("canvas", {
        ref: "watermarkGary",
        staticStyle: { display: "none" },
        attrs: { id: "watermark-img-gary-canvas" },
      }),
      _vm.isGy
        ? [
            _c(
              "el-button",
              {
                staticClass: "help_btn",
                attrs: { icon: "el-icon-question" },
                on: { click: _vm.toHelp },
              },
              [_vm._v("帮助中心")]
            ),
            _c("User"),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }