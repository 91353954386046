// 旅程行模版
import request from '@/utils/request'

// 获取行模版列表
export function getRowTempList() {
    return request({
        url: `/journey/row-template/list`,
        method: 'get',
    })
}

// 创建行模版
export function createRowTemp(data) {
    return request({
        url: `/journey/row-template/add`,
        method: 'post',
        data
    })
}

// 更新行模版
export function updateRowTemp(data) {
    return request({
        url: `/journey/row-template/update`,
        method: 'put',
        data
    })
}

// 根据id删除行模版
export function deleteRowTempById(id) {
    return request({
        url: `/journey/row-template/delete?id=${id}`,
        method: 'delete'
    })
}


// 获取行模版
export function getContentType() {
    return request({
        url: `/journey/row-template/config`,
        method: 'get'
    })
}

// 
export function uploadImage(data) {
    return request({
        url: `/journey/row-template/picture/upload`,
        method: 'post',
        data,
        header: {
            'content-type': 'multipart/form-data'
        }
    })
}

export function getImage(id) {
    return request({
        url: `/journey/row-template/picture?id=${id}`,
        method: 'get',
        responseType:'blob',
    })
}